import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  isDevMode,
  transformDayjsToMexicoStartOfDay,
} from "../../../../shared/helpers";
import { useAuthHook } from "../../../../hooks";
import { AttendanceRecordReportViewSelector } from "../components/AttendanceRecordReportViewSelector";
import { AttendanceReport } from "../components/AttendanceReport";
import { AttendanceReportsService } from "../../../../services";
import { CollaboratorAttendanceReportResponse } from "../../../../shared/types";
import dayjs from "dayjs";

export const AttendanceRecordReport = () => {
  const attendanceReportsService = new AttendanceReportsService();
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [attendanceReport, setAttendanceReport] =
    useState<CollaboratorAttendanceReportResponse>();
  const [isLoading, setIsLoading] = useState(true);
  // *****GLOBALS***** //

  // *****SELECTORS***** //

  // *****STATES***** //

  // *****CUSTOM HOOKS***** //
  const { currentUserId } = useAuthHook();

  // *****USE EFFECTS***** //s
  useEffect(() => {
    if (!startDate || !endDate) return;

    const mxStartDate = transformDayjsToMexicoStartOfDay(startDate);
    const mxEndDate = transformDayjsToMexicoStartOfDay(endDate);

    setIsLoading(true);

    attendanceReportsService
      .getByCollaboratorId(currentUserId!, {
        periodStartDate: mxStartDate.toISOString(),
        periodEndDate: mxEndDate.toISOString(),
      })
      .then((response) => {
        setAttendanceReport(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [startDate, endDate]);

  // fetch attendance records by dates
  // be able to select dates and periods... Like stats
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <AttendanceRecordReportViewSelector
        handleSetStartDate={setStartDate}
        handleSetEndDate={setEndDate}
        isLoading={isLoading}
      />
      {isLoading && <CircularProgress />}
      {!isLoading && attendanceReport && (
        <>
          <AttendanceReport
            key={attendanceReport?.collaboratorId}
            report={attendanceReport}
          />
        </>
      )}
      {isDevMode && (
        <pre>
          {JSON.stringify({ attendanceRecords: attendanceReport }, null, 2)}
        </pre>
      )}
    </Box>
  );
};
