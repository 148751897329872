import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import {
  EmploymentFormValues,
  ICollaborator,
  IJobResponse,
} from "../../../../../shared/types";
import { useAuthHook, useCollaboratorHook } from "../../../../../hooks";
import CalculateIcon from "@mui/icons-material/Calculate";
import { useJobsHook } from "../../../../../shared/hooks";
import { CollaboratorAvatarSelect } from "../../../../../components/inputs/CollaboratorAvatarSelect";
import { EmploymentService } from "../../../../../services";
import { PaymentType, PayrollIncomeType } from "../../../../../shared/enums";
import { employmentValidationSchema } from "../schemas/employment.schema";
import { FormikField } from "../../../../../components/formik/FormikField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  fireSwalError,
  fireSwalSuccess,
  getOptionsFromEnum,
} from "../../../../../helpers";
import { useNavigate } from "react-router-dom";
import { CustomSelect } from "../../../../../components/inputs/CustomSelect";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  formValues: EmploymentFormValues;
  handleSelectCollaborator?: (collaboratorId: string) => void;
  displayOnly?: boolean;
};

export const EmploymentForm = ({
  formValues: initialFormValues,
  handleSelectCollaborator,
  displayOnly = false,
}: Props) => {
  const navigate = useNavigate();

  const { jobs, getJobById } = useJobsHook();
  const { getCollaboratorById } = useCollaboratorHook();

  const [selectedJob, setSelectedJob] = useState<IJobResponse | undefined>(
    getJobById(initialFormValues.jobId)
  );
  const [selectedCollaborator, setSelectedCollaborator] = useState<
    ICollaborator | undefined
  >(getCollaboratorById(initialFormValues.collaboratorId));
  const [enableRecalculate, setEnableRecalculate] = useState(false);

  const service = new EmploymentService();
  const { isAdmin } = useAuthHook();

  const handleRecalculate = (
    values: EmploymentFormValues,
    setValues: (values: EmploymentFormValues) => void
  ) => {
    if (displayOnly) return;
    if (!selectedJob || !selectedCollaborator) return;

    const recalculated = service.recalculateEmployment({
      employment: values,
      job: selectedJob,
      collaborator: selectedCollaborator,
    });

    setValues(recalculated);
  };

  const handleCollaboratorChange = (collaboratorId: string) => {
    if (displayOnly) return;
    if (handleSelectCollaborator) {
      handleSelectCollaborator(collaboratorId);
    }
    setSelectedCollaborator(getCollaboratorById(collaboratorId));
  };

  const handleSubmit = async (values: EmploymentFormValues) => {
    if (displayOnly) return;
    try {
      const request = service.transformFormValuesToRequest(values);
      if (initialFormValues.id) {
        const response = await service.update(initialFormValues.id, request);
        fireSwalSuccess("Empleo actualizado correctamente");
      } else {
        const response = await service.create(request);
        fireSwalSuccess("Empleo creado correctamente");
      }
      navigate("/dashboard/collaborators/employments");
    } catch (error: unknown) {
      if (error instanceof Error) {
        fireSwalError(error.message);
      } else {
        fireSwalError("Error desconocido");
      }
    }
  };

  useEffect(() => {
    if (selectedJob && selectedCollaborator) {
      setEnableRecalculate(true);
    } else {
      setEnableRecalculate(false);
    }
  }, [selectedJob, selectedCollaborator]);

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={employmentValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        setValues,
        setFieldValue,
        setFieldTouched,
        touched,
        errors,
      }) => (
        <Form>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {/* Basic Information */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CollaboratorAvatarSelect
                  value={values.collaboratorId}
                  handleCollaboratorChange={(value) => {
                    setFieldValue("collaboratorId", value);
                    handleCollaboratorChange(value);
                  }}
                  disabled={displayOnly}
                  error={
                    touched.collaboratorId && Boolean(errors.collaboratorId)
                  }
                  helperText={
                    touched.collaboratorId && errors.collaboratorId
                      ? errors.collaboratorId
                      : ""
                  }
                  onBlur={() => setFieldTouched("collaboratorId", true)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Posición</InputLabel>
                  <Select
                    value={values.jobId}
                    label="Posición"
                    onChange={(e) => {
                      setFieldValue("jobId", e.target.value);
                      const job = getJobById(e.target.value);
                      if (job) {
                        setSelectedJob(job);
                      }
                    }}
                    disabled={displayOnly}
                  >
                    {jobs?.map((job) => (
                      <MenuItem key={job.id} value={job.id}>
                        {job.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <CustomSelect
                  options={getOptionsFromEnum(PaymentType)}
                  label="Tipo de pago"
                  onChange={(e) => setFieldValue("paymentType", e.target.value)}
                  disabled={displayOnly}
                  value={values.paymentType}
                />
              </Grid>

              <Grid item xs={6}>
                <FormikField
                  fullWidth
                  name="weeklyHours"
                  label="Horas semanales"
                  type="number"
                  disabled={displayOnly}
                />
              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  label="Fecha de inicio"
                  value={values.employmentStartDate}
                  onChange={(date) =>
                    setFieldValue("employmentStartDate", date)
                  }
                  disabled={displayOnly}
                />
              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  label="Fecha de fin"
                  value={values.employmentEndDate}
                  onChange={(date) => setFieldValue("employmentEndDate", date)}
                  disabled={displayOnly}
                />
              </Grid>
            </Grid>

            {/* Recalculate Button */}
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <Button
                variant="contained"
                color="warning"
                size="large"
                sx={{
                  fontWeight: "bold",
                  px: 4,
                  py: 1.5,
                  boxShadow: 4,
                  "&:hover": {
                    boxShadow: 6,
                  },
                }}
                startIcon={<CalculateIcon />}
                disabled={!enableRecalculate || displayOnly}
                onClick={() => handleRecalculate(values, setValues)}
                type="button"
              >
                Recalcular
              </Button>
            </Box>
            {/* Income Information */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Información de Ingresos
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="fixedIncomeByPosition"
                    label="Ingreso Fijo por Posición"
                    type="number"
                    numberType="currency"
                    onChange={(e) => {
                      const fixedIncomeByPosition = Number(e.target.value) || 0;
                      const additionalFixedIncome =
                        Number(values.additionalFixedIncome) || 0;

                      setFieldValue(
                        "fixedIncomeByPosition",
                        fixedIncomeByPosition
                      );
                      setFieldValue(
                        "fixedIncome",
                        fixedIncomeByPosition + additionalFixedIncome
                      );
                    }}
                    disabled={displayOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="additionalFixedIncome"
                    label="Ingreso Fijo Adicional"
                    type="number"
                    onChange={(e) => {
                      const additionalFixedIncome = Number(e.target.value) || 0;
                      const fixedIncomeByPosition =
                        Number(values.fixedIncomeByPosition) || 0;

                      setFieldValue(
                        "additionalFixedIncome",
                        additionalFixedIncome
                      );
                      setFieldValue(
                        "fixedIncome",
                        fixedIncomeByPosition + additionalFixedIncome
                      );
                    }}
                    numberType="currency"
                    disabled={displayOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="fixedIncome"
                    label="Ingreso Fijo Total"
                    type="number"
                    disabled={true}
                    readOnly={true}
                    numberType="currency"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="minimumOrdinaryIncome"
                    label="Ingreso Ordinario Mínimo"
                    type="number"
                    numberType="currency"
                    disabled={displayOnly}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Bonuses */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Bonificaciones
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="seniorityBonusPercentage"
                    label="Bono por Antigüedad (%)"
                    type="number"
                    readOnly={true}
                    disabled={displayOnly}
                    numberType="percentage"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="comissionBonusPercentage"
                    label="Bono por Comisión (%)"
                    type="number"
                    numberType="percentage"
                    readOnly={true}
                    disabled={displayOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="degreeBonus"
                    label="Bono por Título"
                    type="number"
                    numberType="currency"
                    disabled={displayOnly}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="receptionBonus"
                    label="Bono por Recepción"
                    type="number"
                    disabled={displayOnly}
                    numberType="currency"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Support and Benefits */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Apoyos y Beneficios
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="trainingSupport"
                    label="Apoyo para Capacitación"
                    type="number"
                    disabled={displayOnly}
                    numberType="currency"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="physicalActivitySupport"
                    label="Apoyo para Actividad Física"
                    type="number"
                    disabled={displayOnly}
                    numberType="currency"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Payroll Information */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Información de Nómina
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="contributionBaseSalary"
                    label="Salario Base de Cotización"
                    type="number"
                    numberType="currency"
                    disabled={displayOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="averageOrdinaryIncome"
                    label="Ingreso Ordinario Promedio"
                    type="number"
                    disabled={displayOnly}
                    numberType="currency"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="averageIntegratedIncome"
                    label="Ingreso Integrado Promedio"
                    type="number"
                    disabled={displayOnly}
                    numberType="currency"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikField
                    fullWidth
                    name="averageComissionIncome"
                    label="Ingreso por Comisión Promedio"
                    type="number"
                    disabled={displayOnly}
                    numberType="currency"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Extra Compensations */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Compensaciones Extra
              </Typography>

              {values.extraCompensations?.map((comp, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    p: 2,
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormikField
                        fullWidth
                        name={`extraCompensations.${index}.name`}
                        label="Nombre"
                        disabled={displayOnly}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikField
                        fullWidth
                        name={`extraCompensations.${index}.description`}
                        label="Descripción"
                        disabled={displayOnly}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikField
                        fullWidth
                        name={`extraCompensations.${index}.amount`}
                        label="Monto"
                        type="number"
                        numberType="currency"
                        disabled={displayOnly}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel>Tipo de Ingreso</InputLabel>
                        <Select
                          value={comp.incomeType}
                          label="Tipo de Ingreso"
                          onChange={(e) =>
                            setFieldValue(
                              `extraCompensations.${index}.incomeType`,
                              e.target.value
                            )
                          }
                          disabled={displayOnly}
                        >
                          {Object.values(PayrollIncomeType).map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>Asistencia</InputLabel>
                        <Select
                          value={comp.attendanceRelated}
                          label="Asistencia"
                          onChange={(e) =>
                            setFieldValue(
                              `extraCompensations.${index}.attendanceRelated`,
                              e.target.value
                            )
                          }
                          disabled={displayOnly}
                        >
                          <MenuItem value="true">Sí</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <IconButton
                        onClick={() => {
                          const newCompensations =
                            values.extraCompensations.filter(
                              (_, i) => i !== index
                            );
                          setFieldValue("extraCompensations", newCompensations);
                        }}
                        disabled={displayOnly}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={() => {
                  const newCompensation = {
                    name: "",
                    description: "",
                    attendanceRelated: false,
                    amount: 0,
                    incomeType: PayrollIncomeType.BASE_INCOME,
                  };
                  setFieldValue("extraCompensations", [
                    ...(values.extraCompensations || []),
                    newCompensation,
                  ]);
                }}
                disabled={displayOnly}
                sx={{ mt: 1 }}
              >
                Agregar Compensación
              </Button>
            </Box>

            {/* Submit Button */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={displayOnly}
              >
                Guardar
              </Button>
            </Box>

            {isAdmin && (
              <>
                <pre>{JSON.stringify(values, null, 2)}</pre>
                <pre>{JSON.stringify(selectedJob, null, 2)}</pre>
                <pre>{JSON.stringify(selectedCollaborator, null, 2)}</pre>
              </>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
