import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IJob, IJobRequest } from "../../../../../../shared/types";
import { JobService } from "../../../../../../services";

import { CircularProgress } from "@mui/material";
import { JobForm } from "../../components";

export const EditJob = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [initialValues, setInitialValues] = useState<IJobRequest>();

  useEffect(() => {
    if (id) {
      const fetchById = async () => {
        try {
          const service = new JobService();
          const response = await service.getById(id);
          const element = response.data;
          element.annualRaisePercent = element.annualRaisePercent
            ? element.annualRaisePercent
            : 0.025;
          element.quarterlyCommissionRaisePercent =
            element.quarterlyCommissionRaisePercent
              ? element.quarterlyCommissionRaisePercent
              : 0.025;
          element.incomeMultiplier = element.incomeMultiplier
            ? element.incomeMultiplier
            : 1;
          element.expectedCommissionsPercentage =
            element.expectedCommissionsPercentage
              ? element.expectedCommissionsPercentage
              : 0.4;
          element.expectedMinimumIncome = element.expectedMinimumIncome
            ? element.expectedMinimumIncome
            : 0;

          setInitialValues(response.data);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };

      fetchById();
    }
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!initialValues) {
    return <div>Job not found</div>;
  }

  return <JobForm initialValues={initialValues} />;
};
