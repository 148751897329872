import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmploymentService } from "../../../../../../services";
import { EmploymentFormValues } from "../../../../../../shared/types";
import { EmploymentFormContainer } from "../../components";
import { useAuthHook } from "../../../../../../hooks";
import { fireSwalError } from "../../../../../../helpers";

export const EmploymentCopy = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAdminOrManager } = useAuthHook();

  const service = new EmploymentService();
  const [employment, setEmployment] = useState<EmploymentFormValues | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    service
      .getById(id!)
      .then((employment) => {
        const employmentFormValues = service.transformResponseToFormValues(
          employment.data
        );
        setEmployment(employmentFormValues);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [id]);

  if (!isAdminOrManager) {
    fireSwalError("No tienes permisos para acceder a esta página").then(() => {
      navigate("/dashboard/collaborators/employments");
    });
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!employment) {
    return <div>Loading...</div>;
  }

  return <EmploymentFormContainer formValues={employment} />;
};
