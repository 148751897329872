// TODO: Delete this slice
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  CollaboratorTimeOffOverview,
  TimeOffRequest,
} from "../../shared/types/time-off-request.types";

interface TimeOffRequestState {
  timeOffRequests: TimeOffRequest[];
  activeTimeOffRequest: TimeOffRequest | undefined;
  isLoading: boolean;
  collaboratorsTimeOffsOverview: CollaboratorTimeOffOverview[];
  activeCollaboratorTimeOffOverview: CollaboratorTimeOffOverview | undefined;
  isAllLoading: boolean;
}

const initialState: TimeOffRequestState = {
  timeOffRequests: [],
  activeTimeOffRequest: undefined,
  isLoading: true,
  collaboratorsTimeOffsOverview: [],
  activeCollaboratorTimeOffOverview: undefined,
  isAllLoading: true,
};

export const timeOffRequestsSlice = createSlice({
  name: "timeOffRequests",
  initialState,
  reducers: {
    setTimeOffRequests: (state, action: PayloadAction<TimeOffRequest[]>) => {
      state.timeOffRequests = action.payload;
      // Todo load the timeOff of the collaborator
    },
    setCollaboratorsTimeOffOverview: (
      state,
      action: PayloadAction<CollaboratorTimeOffOverview[]>
    ) => {
      // state.isLoading = false;
      state.collaboratorsTimeOffsOverview = action.payload;
    },

    setActiveCollaboratorTimeOffOverview: (
      state,
      action: PayloadAction<CollaboratorTimeOffOverview>
    ) => {
      state.activeCollaboratorTimeOffOverview = action.payload;
    },

    setActiveTimeOffRequest: (state, action: PayloadAction<TimeOffRequest>) => {
      state.activeTimeOffRequest = action.payload;
    },
    addTimeOffRequest: (state, action: PayloadAction<TimeOffRequest>) => {
      state.timeOffRequests.push(action.payload);
    },
    updateTimeOffRequest: (state, action: PayloadAction<TimeOffRequest>) => {
      state.timeOffRequests = state.timeOffRequests.map((timeOffRequest) => {
        if (timeOffRequest._id === action.payload._id) {
          return action.payload;
        }

        return timeOffRequest;
      });
    },
    deleteTimeOffRequestById: (state, action: PayloadAction<string>) => {
      state.timeOffRequests = state.timeOffRequests.filter(
        (timeOffRequest) => timeOffRequest._id !== action.payload
      );
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsAllLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllLoading = action.payload;
    },
  },
});

export const {
  setTimeOffRequests,
  setCollaboratorsTimeOffOverview,
  setActiveCollaboratorTimeOffOverview,
  setLoading,
  setIsAllLoading,
} = timeOffRequestsSlice.actions;
