import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

import { Box, Button, Grid } from "@mui/material";

import { useAppDispatch } from "../../../../../hooks";
import { SimpleFormikTextField } from "../../../../../components/form/SimpleFormikTextField";
import { IJob, IOption } from "../../../../../shared/types";
import { JobService } from "../../../../../services";
import { FormikOptionSelect } from "../../../../../components";
import { jobThunks } from "../../../../../store/thunks";
import { FormikSwitch } from "../../../../../components/form/FormikSwitch";
import { PaymentType } from "../../../../../shared/enums";

const validationSchema = Yup.object().shape({
  id: Yup.string().optional(),
  active: Yup.boolean().required("Active status is required"),
  annualRaisePercent: Yup.number()
    .min(0, "Annual raise percent must be at least 0")
    .max(100, "Annual raise percent cannot exceed 100")
    .required("Annual raise percent is required"),
  quarterlyComissionRaisePercent: Yup.number()
    .min(0, "Quarterly comission raise percent must be at least 0")
    .max(100, "Quarterly comission raise percent cannot exceed 100")
    .required("Quarterly comission raise percent is required"),
  baseIncome: Yup.number()
    .min(0, "Base income must be a positive number")
    .optional(),
  description: Yup.string().optional(),
  hourlyRate: Yup.number()
    .min(0, "Hourly rate must be a positive number")
    .when("paymentType", {
      is: PaymentType.HOURLY,
      then: Yup.number().required("Hourly rate is required for hourly jobs"),
    }),
  minimumIncome: Yup.number()
    .min(0, "Minimum income must be a positive number")
    .optional(),
  paymentType: Yup.mixed<PaymentType>()
    .oneOf(Object.values(PaymentType), "Invalid payment type")
    .required("Payment type is required"),
  title: Yup.string()
    .min(3, "Title must be at least 3 characters long")
    .max(100, "Title cannot exceed 100 characters")
    .required("Job title is required"),
  sortingOrder: Yup.number().required("Sorting order is required"),
  proportionalFactor: Yup.number(),
  expectedComissionsPercentage: Yup.number(),
  expectedMinimumIncome: Yup.number(),
  expressBranchCompensation: Yup.number(),
  createdAt: Yup.date().optional(),
  createdBy: Yup.string().optional(),
  updatedAt: Yup.date().optional(),
  updatedBy: Yup.string().optional(),
});

type Props = {
  initialValues: IJob;
};

export const JobForm = ({ initialValues }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [paymentTypes, setPaymentTypes] = useState<IOption[]>([]);

  useEffect(() => {
    const fetchBranches = async () => {
      const service = new JobService();
      const paymentTypes = await service.getPaymentTypes();
      setPaymentTypes(paymentTypes);
    };

    fetchBranches();
  }, []);

  const handleSubmit = async (values: IJob) => {
    try {
      if (values.id) {
        await dispatch(jobThunks.updateItem(values)).unwrap();
      } else {
        await dispatch(jobThunks.createItem(values)).unwrap();
      }

      navigate("/dashboard/parameters/jobs");
    } catch (error) {
      console.error("Error while submitting the form:", error);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="title"
                  label="Job title"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="description"
                  label="Job description"
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikOptionSelect
                  label="Payment Type"
                  name="paymentType"
                  options={paymentTypes}
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="annualRaisePercent"
                  label="Annual Raise Percent (%)"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="quarterlyComissionRaisePercent"
                  label="Quarterly Comission Raise Percent (%)"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="baseIncome"
                  label="Base Income"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="hourlyRate"
                  label="Hourly Rate"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="minimumIncome"
                  label="Minimum Income"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="sortingOrder"
                  label="Sorting Order"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="proportionalFactor"
                  label="Proportional Factor"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="expectedComissionsPercentage"
                  label="Expected Comissions Percentage"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="expectedMinimumIncome"
                  label="Expected Minimum Income"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleFormikTextField
                  name="expressBranchCompensation"
                  label="Express Branch Compensation"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <FormikSwitch label="Active" name="active" />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
