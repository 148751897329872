import { useCallback, useMemo } from "react";

import { IJob } from "../types";
import { useAppSelector } from "../../hooks";

export const useJobsHook = () => {
  const { jobs } = useAppSelector((state) => state.jobs);

  const getJobById = useCallback(
    (id: string) => {
      return jobs.find((job: IJob) => job.id === id);
    },
    [jobs]
  );

  const getJobTitleById = useCallback(
    (id: string) => {
      return getJobById(id)?.title;
    },
    [getJobById]
  );

  const getJobByTitle = useCallback(
    (title: string) => {
      return jobs.find(
        (job: IJob) => job.title.toLowerCase() === title.toLowerCase()
      );
    },
    [jobs]
  );

  return { getJobById, getJobTitleById, getJobByTitle, jobs };
};
