import React, { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  useAuthHook,
  useCollaboratorHook,
} from "../../../../hooks";
import { EmploymentThunks } from "../../../../store/thunks";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { CollaboratorAvatarSelect } from "../../../../components/inputs/CollaboratorAvatarSelect";

import { CustomSelect } from "../../../../components/inputs/CustomSelect";
import { PaymentType } from "../../../../shared/enums";
import { EmploymentList } from "./components";

export const EmploymentsIndex = () => {
  const dispatch = useAppDispatch();
  const { employments, isLoading } = useAppSelector(
    (state) => state.employments
  );
  const { activeCollaboratorsIds } = useCollaboratorHook();

  const { isAdminOrManager, currentUserId } = useAuthHook();
  console.log({ isAdminOrManager });

  const [hideNotActiveEmployments, setHideNotActiveEmployments] =
    useState(true);
  const [showOnlyActiveCollaborators, setShowOnlyActiveCollaborators] =
    useState(true);

  const [collaboratorId, setCollaboratorId] = useState<string>(
    isAdminOrManager ? "" : currentUserId!
  );

  const [paymentType, setPaymentType] = useState<string>("");

  useEffect(() => {
    const query: Record<string, any> = {};

    // Handle collaborator filtering
    if (collaboratorId) {
      query.collaboratorId = collaboratorId;
    } else if (showOnlyActiveCollaborators) {
      query.$collaboratorId = `$in:${activeCollaboratorsIds}`;
    }

    // Handle active employment filtering
    if (hideNotActiveEmployments) {
      query.isActive = true;
    }

    // Handle payment type filtering
    if (paymentType) {
      query.paymentType = paymentType;
    }

    dispatch(EmploymentThunks.fetchItems(query));
  }, [
    collaboratorId,
    hideNotActiveEmployments,
    paymentType,
    activeCollaboratorsIds,
    showOnlyActiveCollaborators,
  ]);
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Typography variant="h6">Empleos</Typography>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: 250,
              bgcolor: "background.paper",
              p: 2,
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Filtros de estado
            </Typography>
            <FormControlLabel
              disabled={!isAdminOrManager}
              control={
                <Switch
                  checked={showOnlyActiveCollaborators}
                  onChange={() =>
                    setShowOnlyActiveCollaborators(!showOnlyActiveCollaborators)
                  }
                />
              }
              label="Ocultar colaboradores inactivos"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={hideNotActiveEmployments}
                  onChange={() =>
                    setHideNotActiveEmployments(!hideNotActiveEmployments)
                  }
                />
              }
              label="Ocultar empleos inactivos"
            />
          </Box>

          {/* Selects Group */}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexGrow: 1,
              flexWrap: "wrap",
            }}
          >
            <CollaboratorAvatarSelect
              value={collaboratorId}
              handleCollaboratorChange={setCollaboratorId}
              collaboratorsShown={
                showOnlyActiveCollaborators ? "active" : "all"
              }
              disabled={!isAdminOrManager}
            />
            <CustomSelect
              options={Object.values(PaymentType).map((type) => ({
                label: type,
                value: type,
              }))}
              label="Tipo de pago"
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value as PaymentType)}
              sx={{ minWidth: 200 }}
              withNoneOption
              noneLabel="ALL"
            />
          </Box>
        </Box>
        {isLoading ? <CircularProgress /> : <EmploymentList />}
      </Box>
      {/* <pre>{JSON.stringify(employments?.length, null, 2)}</pre>
      <pre>{JSON.stringify(employments, null, 2)}</pre> */}
    </>
  );
};
