import { ISalaryDataRequest, ISalaryDataResponse } from "../shared/types";

import { BaseService } from "./base.service";

export class SalaryDataService extends BaseService<
  ISalaryDataResponse,
  ISalaryDataRequest
> {
  constructor() {
    super("/salary-data");
  }
}
