import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useCollaboratorHook } from "../../hooks";

type Props = {
  value: string;
  handleCollaboratorChange: (value: string) => void;
  collaboratorsShown?: "active" | "all";
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onBlur?: () => void;
};

export const CollaboratorAvatarSelect = ({
  value,
  handleCollaboratorChange,
  collaboratorsShown = "active",
  disabled = false,
  error,
  helperText,
  onBlur,
}: Props) => {
  const { collaborators, getCollaboratorThumbnailUrlByCollabortorId } =
    useCollaboratorHook();

  const filteredCollaborators = collaborators
    ?.filter((c) => {
      if (collaboratorsShown === "active") {
        return c.isActive;
      }
      return true;
    })
    .sort((a, b) => a.col_code.localeCompare(b.col_code));

  return (
    <FormControl fullWidth>
      <InputLabel>Colaborador</InputLabel>
      <Select
        value={value}
        label="Colaborador"
        onChange={(e) => handleCollaboratorChange(e.target.value)}
        error={error}
        onBlur={onBlur}
        renderValue={(selected) => {
          const collaborator = filteredCollaborators?.find(
            (c) => c.id === selected
          );
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Avatar
                src={getCollaboratorThumbnailUrlByCollabortorId(
                  collaborator!.id
                )}
                sx={{ width: 24, height: 24 }}
              />
              <span>{collaborator?.col_code}</span>
            </Box>
          );
        }}
        sx={{
          height: "56px", // Match the height of the position dropdown
        }}
        disabled={disabled}
      >
        {" "}
        <MenuItem value="">
          <ListItemText primary="Select a collaborator" sx={{ margin: 0 }} />
        </MenuItem>
        {filteredCollaborators?.map((collaborator) => (
          <MenuItem
            key={collaborator.id}
            value={collaborator.id}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <ListItemAvatar sx={{ minWidth: 32 }}>
              <Avatar
                src={getCollaboratorThumbnailUrlByCollabortorId(
                  collaborator.id
                )}
                sx={{ width: 24, height: 24 }}
              />
            </ListItemAvatar>
            <ListItemText primary={collaborator.col_code} sx={{ margin: 0 }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
