import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { jobThunks } from "../../../../../store/thunks";

export const JobView = () => {
  const { id } = useParams();
  const { jobs } = useAppSelector((state) => state.jobs);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const job = jobs.find((job) => job.id === id);

  if (!job) {
    return <div>Job not found</div>;
  }

  const handleEdit = (id: string) => {
    navigate(`/dashboard/parameters/jobs/edit/${id}`);
  };

  const handleDelete = (id: string) => {
    dispatch(jobThunks.deleteItem(id));
    navigate(`/dashboard/parameters/jobs`);
  };

  return (
    <Box p={2}>
      <Card variant="outlined">
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" gutterBottom>
              {job.title}
            </Typography>
            <Box>
              <IconButton onClick={() => handleEdit(job.id!)} color="primary">
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDelete(job.id!)}
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <Typography variant="body1">
              <strong>Description:</strong> {job.description}
            </Typography>
            <Typography variant="body1">
              <strong>Base Income:</strong> ${job.baseIncome?.toLocaleString()}
            </Typography>
            <Typography variant="body1">
              <strong>Minimum Income:</strong> $
              {job.minimumIncome?.toLocaleString()}
            </Typography>
            <Typography variant="body1">
              <strong>Hourly Rate:</strong> ${job.hourlyRate?.toLocaleString()}
            </Typography>
            <Typography variant="body1">
              <strong>Payment Type:</strong> {job.paymentType}
            </Typography>
            <Typography variant="body1">
              <strong>Annual Raise Percent:</strong> {job.annualRaisePercent}%
            </Typography>
            <Typography variant="body1">
              <strong>Active:</strong> {job.active ? "Yes" : "No"}
            </Typography>
            <Typography variant="body1">
              <strong>Income Multiplier:</strong> {job.incomeMultiplier}
            </Typography>
            <Typography variant="body1">
              <strong>Expected Comissions Percentage:</strong>{" "}
              {job.expectedCommissionsPercentage}%
            </Typography>
            <Typography variant="body1">
              <strong>Expected Minimum Income:</strong> $
              {job.expectedMinimumIncome?.toLocaleString()}
            </Typography>
            <Typography variant="body1">
              <strong>Express Branch Compensation:</strong> $
              {job.expressBranchCompensation?.toLocaleString()}
            </Typography>
            <Typography variant="body1">
              <strong>Created At:</strong>{" "}
              {job.createdAt ? new Date(job.createdAt).toLocaleString() : "-"}
            </Typography>
            <Typography variant="body1">
              <strong>Updated At:</strong>{" "}
              {job.updatedAt ? new Date(job.updatedAt).toLocaleString() : "-"}
            </Typography>
            <Typography variant="body1">
              <strong>Created By:</strong> {job.createdBy}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
