import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Box,
  Chip,
  Stack,
  Grid,
} from "@mui/material";
import {
  CollaboratorAttendanceReportResponse,
  WorkingDayType,
} from "../../../../shared/types";
import {
  formatDateToDateDay,
  formatTimeToLocalTime,
} from "../../../../shared/helpers/date.helpers";
import { useCollaboratorHook } from "../../../../hooks";

type Props = {
  report: CollaboratorAttendanceReportResponse;
};

export const AttendanceReport = ({ report }: Props) => {
  const nonZeroPeriodHours = Object.entries(report.periodHours).filter(
    ([_, value]) => value > 0
  );

  const {
    getCollaboratorThumbnailUrlByCollabortorId,
    getCollaboratorCodeById,
  } = useCollaboratorHook();

  return (
    <Paper sx={{ p: 3, maxWidth: "100%", mb: 3 }}>
      {/* Header */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
        <Avatar
          sx={{ width: 80, height: 80, mr: 2 }}
          src={getCollaboratorThumbnailUrlByCollabortorId(
            report.collaboratorId
          )}
        />
        <Typography variant="h5">
          {getCollaboratorCodeById(report.collaboratorId)}
        </Typography>
      </Box>

      {/* Day Reports Table */}
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Shift</TableCell>
              <TableCell>Attendance</TableCell>
              <TableCell>Time Off</TableCell>
              <TableCell>Working Day Type</TableCell>
              <TableCell align="right">Worked Hours</TableCell>
              <TableCell align="right">Extra Hours</TableCell>
              <TableCell align="right">Delay (min)</TableCell>
              <TableCell align="right">Anticipated (min)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.collaboratorDayReports.map((day) => (
              <TableRow key={day.date}>
                <TableCell>{formatDateToDateDay(day.date)}</TableCell>
                <TableCell>
                  {day.shiftStart && day.shiftEnd
                    ? `${formatTimeToLocalTime(
                        day.shiftStart
                      )} - ${formatTimeToLocalTime(day.shiftEnd)}`
                    : "-"}
                </TableCell>
                <TableCell>
                  {day.attendanceStart
                    ? `${formatTimeToLocalTime(day.attendanceStart)}${
                        day.attendanceEnd
                          ? ` - ${formatTimeToLocalTime(day.attendanceEnd)}`
                          : ""
                      }`
                    : "-"}
                </TableCell>
                <TableCell>
                  {day.timeOffRequestType ? (
                    <Chip
                      label={day.timeOffRequestType}
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  <Chip
                    label={day.workingDayType}
                    size="small"
                    color={getWorkingDayTypeColor(day.workingDayType)}
                  />
                </TableCell>
                <TableCell align="right">
                  {day.workedHours.toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  <Chip
                    label={day.extraHours.toFixed(2)}
                    size="small"
                    color={
                      day.extraHours <= -4 || day.extraHours >= 8
                        ? "error"
                        : day.extraHours >= -0.25 && day.extraHours <= 0.5
                        ? "default"
                        : day.extraHours > 0.5
                        ? "info"
                        : "default"
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <Chip
                    label={day.delayMinutes}
                    size="small"
                    color={
                      day.delayMinutes <= -480
                        ? "error"
                        : day.delayMinutes <= 0
                        ? "success"
                        : day.delayMinutes <= 10
                        ? "info"
                        : day.delayMinutes <= 30
                        ? "warning"
                        : "error"
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <Chip
                    label={day.anticipatedMinutes * -1}
                    size="small"
                    color={
                      day.anticipatedMinutes <= -480
                        ? "error"
                        : day.anticipatedMinutes <= 0
                        ? "success"
                        : day.anticipatedMinutes <= 10
                        ? "info"
                        : day.anticipatedMinutes <= 30
                        ? "warning"
                        : "error"
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Period Hours */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Period Hours
        </Typography>
        <Grid container spacing={2}>
          {nonZeroPeriodHours.map(([key, value]) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  {formatPeriodHourKey(key)}
                </Typography>
                <Typography variant="h6">{value.toFixed(2)}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Concluded Week Hours */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Concluded Week Hours
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(report.concludedWeeksHours).map(([key, value]) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  {formatConcludedWeekHourKey(key)}
                </Typography>
                <Typography variant="h6">{value.toFixed(2)}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Summary */}
      <Box>
        <Stack direction="row" spacing={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="body2" color="text.secondary">
              Tardiness
            </Typography>
            <Typography variant="h6">{report.tardiness}</Typography>
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Typography variant="body2" color="text.secondary">
              Punctuality Bonus
            </Typography>
            <Chip
              label={report.punctualityBonus ? "Yes" : "No"}
              color={report.punctualityBonus ? "success" : "error"}
            />
          </Paper>
        </Stack>
      </Box>
    </Paper>
  );
};

/*
verridableStringUnion<"default" | "primary" | "warning" | "error" | "success" | "secondary" | "info",
success: green
default: gray
primary: blue
secondary: purple
warning: naranja
error: red
info: blue

*/
const getWorkingDayTypeColor = (
  type: WorkingDayType
):
  | "default"
  | "primary"
  | "warning"
  | "error"
  | "info"
  | "success"
  | "secondary" => {
  switch (type) {
    case WorkingDayType.OrdinaryShift:
      return "success";
    case WorkingDayType.RestDay:
    case WorkingDayType.NonComputableShift:
      return "default";
    case WorkingDayType.Vacation:
    case WorkingDayType.PersonalLeave:
      return "primary";
    case WorkingDayType.SimulatedAsistance:
    case WorkingDayType.SickLeave:
    case WorkingDayType.ForceMajeure:
    case WorkingDayType.JustifiedAbsenceByCompany:
    case WorkingDayType.AuthorizedUnjustifiedAbsence:
    case WorkingDayType.LatePermission:
    case WorkingDayType.EarlyLeavePermission:
    case WorkingDayType.ShiftToBeCompensated:
    case WorkingDayType.CompensationShift:
      return "secondary";
    case WorkingDayType.Tardiness:
    case WorkingDayType.IncompleteRecord:
    case WorkingDayType.UnscheduledWork:
      return "warning";
    case WorkingDayType.UnjustifiedAbsence:
      return "error";
    default:
      return "default";
  }
};

const formatPeriodHourKey = (key: string): string => {
  return key
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .replace("Hours", "")
    .trim();
};

const formatConcludedWeekHourKey = (key: string): string => {
  return key
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
};
