import { EmploymentService } from "../../services";
import { createCRUDThunks } from "../../shared/helpers";
import { EmploymentRequest, EmploymentResponse } from "../../shared/types";

const service = new EmploymentService();

const basicThunks = createCRUDThunks<EmploymentResponse, EmploymentRequest>({
  service,
  resourceName: "employments",
});

export const EmploymentThunks = {
  ...basicThunks,
};
