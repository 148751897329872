import React from "react";

import { JobForm } from "../components/";
import { IJobRequest } from "../../../../../shared/types";
import { PaymentType } from "../../../../../shared/enums";

export const NewJob = () => {
  const initialValues: IJobRequest = {
    title: "",
    description: "",
    paymentType: PaymentType.HOURLY,
    active: true,
    annualRaisePercent: 2.5,
    baseIncome: 0,
    hourlyRate: 0,
    minimumIncome: 0,
    quarterlyCommissionRaisePercent: 2.5,
    sortingOrder: 99,
    incomeMultiplier: 1,
    expectedCommissionsPercentage: 0.4,
    expectedMinimumIncome: 0,
    expressBranchCompensation: 0,
  };

  return <JobForm initialValues={initialValues} />;
};
