import { BaseService } from "./base.service";
import dayjs, { Dayjs } from "dayjs";
import {
  IWeekShiftResponse,
  TimeOffRequestResponse,
  IAttendanceRecordResponse,
  CollaboratorDayShiftDayjs,
  IAttendanceRecordEntityDayjs,
  TimeOffRequestDayjsEntity,
  CollaboratorAttendanceData,
  DayReportData,
  TimeOffStatus,
  TimeOffType,
  ICollaborator,
  WorkingDayType,
  CollaboratorDayReport,
  CollaboratorDaysReportsWrapper,
  IPublicHolidaysResponse,
  IPublicHolidaysEntity,
  PeriodHours,
  ConcludedWeekHours,
  CollaboratorAttendanceReport,
  CollaboratorAttendanceReportResponse,
  ApiResponseList,
  AxiosParams,
  ApiResponseOne,
} from "../shared/types";
import {
  calculateProportionalHours,
  convertUtcDateToMexicoTimeStartOfDay,
  getDayjsRangeFromDates,
  getFirstMondayOfExtendedHalfWeekMx,
  getIsonStringDateMxByDateAndTime,
  getLastSundayOfExtendedHalfWeekMx,
  getMxDayjsDatetimeByDateAndTime,
  getPreviousSunday,
  isDatetimeAfter,
  isDatetimeBefore,
  minutesBetweenDatetimes,
  toMexicoStartOfDay,
  transformDayjsToMexicoStartOfDay,
} from "../shared/helpers";
import {
  MAX_DOUBLE_PLAY_WORK_WEEK_LIMIT,
  MAX_WORK_WEEK_LIMIT,
} from "../shared";
import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";

type GenerateAttendanceReportsArgs = {
  halfWeekStartDate: Dayjs;
  halfWeekEndDate: Dayjs;
  weekShifts: IWeekShiftResponse[];
  timeoffRequests: TimeOffRequestResponse[];
  attendanceRecords: IAttendanceRecordResponse[];
  activeCollaborators: ICollaborator[];
  yearPublicHolidays: IPublicHolidaysResponse;
};

const API_URL = "/attendance-reports";
export class AttendanceReportsService extends BaseService<
  CollaboratorAttendanceReportResponse,
  CollaboratorAttendanceReportResponse
> {
  constructor() {
    super(API_URL);
  }

  async getAll(params?: AxiosParams) {
    const response = await hvpAuthenticatedApi.get<
      ApiResponseList<CollaboratorAttendanceReportResponse>
    >(API_URL, { params });
    return response.data;
  }

  async getByCollaboratorId(collaboratorId: string, params?: AxiosParams) {
    const response = await hvpAuthenticatedApi.get<
      ApiResponseOne<CollaboratorAttendanceReportResponse>
    >(`${API_URL}/${collaboratorId}`, { params });
    return response.data;
  }

  // todo: previous

  public generateAttendanceReports({
    halfWeekStartDate,
    halfWeekEndDate,
    weekShifts,
    timeoffRequests,
    attendanceRecords,
    activeCollaborators,
    yearPublicHolidays,
  }: GenerateAttendanceReportsArgs) {
    const halfWeekStartDateMx =
      transformDayjsToMexicoStartOfDay(halfWeekStartDate);
    const halfWeekEndDateMx = transformDayjsToMexicoStartOfDay(halfWeekEndDate);

    const extendedHalfWeekStartDate =
      getFirstMondayOfExtendedHalfWeekMx(halfWeekStartDate);
    const extendedHalfWeekEndDate =
      getLastSundayOfExtendedHalfWeekMx(halfWeekEndDate);

    const processedDayShifts = this.extractCollaboratorsShiftsMx(weekShifts);
    const processedAttendanceRecords =
      this.processAttendanceRecordsForAttendanceReports(attendanceRecords);
    const processedTimeoffRequests =
      this.processTimeoffRequestsForAttendanceReports(timeoffRequests);

    const processedPublicHolidays =
      this.processPublicHolidaysForAttendanceReports(yearPublicHolidays);

    const collaboratorsAttendanceData =
      this.generateCollaboratorsAttendanceData(
        activeCollaborators,
        processedDayShifts,
        processedAttendanceRecords,
        processedTimeoffRequests
      );

    const collaboratorDaysReportsWrappers = collaboratorsAttendanceData.map(
      (collaboratorAttendanceData) =>
        this.generateCollaboratorDaysReportsWrapper(
          collaboratorAttendanceData,
          extendedHalfWeekStartDate,
          extendedHalfWeekEndDate
        )
    );

    const attendanceReports = collaboratorDaysReportsWrappers.map(
      (collaboratorDaysReportsWrapper) =>
        this.generateCollaboratorAttendanceReport(
          collaboratorDaysReportsWrapper,
          halfWeekStartDateMx,
          halfWeekEndDateMx,
          processedPublicHolidays
        )
    );

    return attendanceReports;
  }

  public extractCollaboratorsShiftsMx(
    weekShifts: IWeekShiftResponse[]
  ): CollaboratorDayShiftDayjs[] {
    const rawShifts = [];

    for (const weekShift of weekShifts) {
      rawShifts.push(...weekShift.shifts);
    }

    return rawShifts.map((shift) => {
      const { shiftDate, startingTime, endingTime } = shift;
      const shiftDateMx = convertUtcDateToMexicoTimeStartOfDay(shift.shiftDate);
      const startingTimeMx = startingTime
        ? getMxDayjsDatetimeByDateAndTime(shiftDate, startingTime)
        : undefined;
      const endingTimeMx = endingTime
        ? getMxDayjsDatetimeByDateAndTime(shiftDate, endingTime)
        : undefined;

      return {
        ...shift,
        shiftDate: shiftDateMx,
        startingTime: startingTimeMx,
        endingTime: endingTimeMx,
      };
    });
  }

  public processAttendanceRecordsForAttendanceReports(
    attendanceRecords: IAttendanceRecordResponse[]
  ): IAttendanceRecordEntityDayjs[] {
    return attendanceRecords.map((attendanceRecord) => ({
      ...attendanceRecord,
      shiftDate: dayjs(attendanceRecord.shiftDate),
      startTime: attendanceRecord.startTime
        ? dayjs(attendanceRecord.startTime)
        : undefined,
      endTime: attendanceRecord.endTime
        ? dayjs(attendanceRecord.endTime)
        : undefined,
    }));
  }

  public processTimeoffRequestsForAttendanceReports(
    timeoffRequests: TimeOffRequestResponse[]
  ) {
    return timeoffRequests.map((timeoffRequest) => ({
      ...timeoffRequest,
      requestedDays: timeoffRequest.requestedDays.map((day) =>
        toMexicoStartOfDay(day)
      ),
      approvedAt: dayjs(timeoffRequest.approvedAt),
      requestedAt: dayjs(timeoffRequest.requestedAt),
    }));
  }

  public processPublicHolidaysForAttendanceReports(
    yearPublicHolidays: IPublicHolidaysResponse
  ): IPublicHolidaysEntity {
    return {
      ...yearPublicHolidays,
      createdAt: new Date(yearPublicHolidays.createdAt),
      updatedAt: new Date(yearPublicHolidays.updatedAt),
      publicHolidays: yearPublicHolidays.publicHolidays.map((publicHoliday) =>
        toMexicoStartOfDay(publicHoliday)
      ),
    };
  }

  public generateCollaboratorsAttendanceData(
    activeCollaborators: ICollaborator[],
    processedDayShifts: CollaboratorDayShiftDayjs[],
    processedAttendanceRecords: IAttendanceRecordEntityDayjs[],
    processedTimeoffRequests: TimeOffRequestDayjsEntity[]
  ): CollaboratorAttendanceData[] {
    const collaboratorsAttendanceData: CollaboratorAttendanceData[] =
      activeCollaborators.map((collaborator) => ({
        collaborator,
        dayShifts: [],
        timeoffRequests: [],
        attendanceRecords: [],
      }));

    processedDayShifts.forEach((shift) => {
      const collaboratorAttendanceData = collaboratorsAttendanceData.find(
        (collaboratorAttendanceData) =>
          collaboratorAttendanceData.collaborator.id === shift.collaboratorId
      );

      if (collaboratorAttendanceData) {
        collaboratorAttendanceData.dayShifts.push(shift);
      }
    });

    processedAttendanceRecords.forEach((attendanceRecord) => {
      const collaboratorAttendanceData = collaboratorsAttendanceData.find(
        (collaboratorAttendanceData) =>
          collaboratorAttendanceData.collaborator.id ===
          attendanceRecord.collaborator
      );

      if (collaboratorAttendanceData) {
        collaboratorAttendanceData.attendanceRecords.push(attendanceRecord);
      }
    });

    processedTimeoffRequests.forEach((timeoffRequest) => {
      const collaboratorAttendanceData = collaboratorsAttendanceData.find(
        (collaboratorAttendanceData) =>
          collaboratorAttendanceData.collaborator.id ===
          timeoffRequest.collaborator
      );
      if (collaboratorAttendanceData) {
        collaboratorAttendanceData.timeoffRequests.push(timeoffRequest);
      }
    });

    return collaboratorsAttendanceData;
  }

  public generateCollaboratorDaysReportsWrapper(
    collaboratorAttendanceData: CollaboratorAttendanceData,
    startingDate: Dayjs,
    endingDate: Dayjs
  ): CollaboratorDaysReportsWrapper {
    // todo instead of generating an array
    const { dayShifts, attendanceRecords, timeoffRequests, collaborator } =
      collaboratorAttendanceData;

    const days = getDayjsRangeFromDates(startingDate, endingDate);

    const dayReportsData: DayReportData[] = days.map((day) => ({
      collaborator,
      date: day,
      shiftStart: undefined,
      shiftEnd: undefined,
      attendanceStart: undefined,
      attendanceEnd: undefined,
      timeOffRequest: undefined,
      isRemote: false,
    }));

    dayShifts.forEach((shift) => {
      const dayReportData = dayReportsData.find((dayReport) =>
        dayReport.date.isSame(shift.shiftDate, "day")
      );
      if (dayReportData) {
        dayReportData.shiftStart = shift.startingTime;
        dayReportData.shiftEnd = shift.endingTime;
        dayReportData.isRemote = shift.isRemote || false;
      }
    });

    attendanceRecords.forEach((attendanceRecord) => {
      const dayReport = dayReportsData.find((dayReport) =>
        dayReport.date.isSame(attendanceRecord.shiftDate, "day")
      );
      if (dayReport) {
        dayReport.attendanceStart = attendanceRecord.startTime;
        dayReport.attendanceEnd = attendanceRecord.endTime;
      }
    });

    timeoffRequests
      .filter(
        (timeoffRequest) => timeoffRequest.status === TimeOffStatus.Approved
      )
      .forEach((timeoffRequest) => {
        timeoffRequest.requestedDays.forEach((day) => {
          const dayReport = dayReportsData.find((dayReport) =>
            dayReport.date.isSame(day, "day")
          );
          if (dayReport) {
            dayReport.timeOffRequest = timeoffRequest.timeOffType;
          }
        });
      });

    const collaboratorDaysReports = dayReportsData.map((dayReportData) =>
      this.generateCollaboratorDayReport(dayReportData)
    );

    return { collaborator, collaboratorDayReports: collaboratorDaysReports };
  }

  public generateCollaboratorDayReport(
    dayReportData: DayReportData
  ): CollaboratorDayReport {
    const {
      collaborator,
      date,
      shiftStart,
      shiftEnd,
      attendanceStart,
      attendanceEnd,
      timeOffRequest,
      isRemote,
    } = dayReportData;

    const { startDate, endDate } = collaborator;

    const assignedHours =
      shiftStart && shiftEnd
        ? calculateProportionalHours(shiftStart, shiftEnd)
        : 0;

    const workedHours =
      attendanceEnd && attendanceStart
        ? calculateProportionalHours(attendanceStart, attendanceEnd)
        : 0;

    const delayMinutes =
      attendanceStart && shiftStart
        ? minutesBetweenDatetimes(shiftStart, attendanceStart)
        : 0;

    const anticipatedMinutes =
      attendanceEnd && shiftEnd
        ? minutesBetweenDatetimes(attendanceEnd, shiftEnd)
        : 0;

    const extraHours = workedHours - assignedHours;

    let dayReport: CollaboratorDayReport = {
      collaborator,
      date,
      shiftStart,
      shiftEnd,
      attendanceStart,
      attendanceEnd,
      workingDayType: WorkingDayType.OrdinaryShift,
      assignedHours,
      workedHours,
      extraHours,
      delayMinutes,
      anticipatedMinutes,
    };

    const setHoursToZero = (dayReport: CollaboratorDayReport) => {
      dayReport.assignedHours = 0;
      dayReport.workedHours = 0;
      dayReport.extraHours = 0;
      dayReport.delayMinutes = 0;
      dayReport.anticipatedMinutes = 0;
    };

    const setWorkedRelatedHoursToZero = (dayReport: CollaboratorDayReport) => {
      dayReport.workedHours = 0;
      dayReport.extraHours = 0;
      dayReport.delayMinutes = 0;
      dayReport.anticipatedMinutes = 0;
    };

    if (
      isDatetimeBefore(date, startDate ? startDate : date) ||
      isDatetimeAfter(date, endDate ? endDate : date)
    ) {
      setHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.NonComputableShift;
      return dayReport;
    }

    if (timeOffRequest === TimeOffType.Vacation) {
      setHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.Vacation;
      return dayReport;
    }

    if (timeOffRequest === TimeOffType.PersonalLeave) {
      setHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.PersonalLeave;
      return dayReport;
    }

    if (workedHours + assignedHours === 0) {
      setHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.RestDay;
      return dayReport;
    }

    if (isRemote) {
      setWorkedRelatedHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.SimulatedAsistance;
      return dayReport;
    }

    if (timeOffRequest === TimeOffType.SickLeaveIMSS) {
      setWorkedRelatedHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.SickLeave;
      return dayReport;
    }

    // todo force majeure
    if (timeOffRequest === TimeOffType.ShiftToBeCompensated) {
      setWorkedRelatedHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.ShiftToBeCompensated;
      return dayReport;
    }

    if (timeOffRequest === TimeOffType.JustifiedAbsenceByCompany) {
      setWorkedRelatedHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.JustifiedAbsenceByCompany;
      return dayReport;
    }

    if (timeOffRequest === TimeOffType.AuthorizedUnjustifiedAbsence) {
      setWorkedRelatedHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.AuthorizedUnjustifiedAbsence;
      return dayReport;
    }

    if (assignedHours > 0 && !attendanceStart && !attendanceEnd) {
      setWorkedRelatedHoursToZero(dayReport);
      dayReport.workingDayType = WorkingDayType.UnjustifiedAbsence;
      return dayReport;
    }

    if (timeOffRequest === TimeOffType.LatePermission) {
      dayReport.delayMinutes = 0;
      if (delayMinutes > 120) {
        dayReport.delayMinutes = delayMinutes - 120;
      }
      dayReport.workingDayType = WorkingDayType.LatePermission;
      return dayReport;
    }

    if (timeOffRequest === TimeOffType.EarlyLeavePermission) {
      dayReport.anticipatedMinutes = 0;
      if (anticipatedMinutes > 120) {
        dayReport.anticipatedMinutes = anticipatedMinutes - 120;
      }
      dayReport.workingDayType = WorkingDayType.EarlyLeavePermission;
      return dayReport;
    }

    if (delayMinutes > 10) {
      dayReport.workingDayType = WorkingDayType.Tardiness;
      return dayReport;
    }

    if (anticipatedMinutes > 10) {
      dayReport.workingDayType = WorkingDayType.EarlyDeparture;
      return dayReport;
    }

    if (assignedHours === 0 && workedHours > 0) {
      dayReport.workingDayType = WorkingDayType.UnscheduledWork;
      return dayReport;
    }

    if (
      (attendanceStart && !attendanceEnd) ||
      (!attendanceStart && attendanceEnd)
    ) {
      dayReport.workingDayType = WorkingDayType.IncompleteRecord;
      setWorkedRelatedHoursToZero(dayReport);
      return dayReport;
    }

    dayReport.workingDayType = WorkingDayType.OrdinaryShift;
    return dayReport;
  }

  public generateCollaboratorAttendanceReport(
    collaboratorDaysReportsWrapper: CollaboratorDaysReportsWrapper,
    halfMonthStartDate: Dayjs,
    halfMonthEndDate: Dayjs,
    yearPublicHolidays: IPublicHolidaysEntity
  ): CollaboratorAttendanceReport {
    const relevantPeriodDates = getDayjsRangeFromDates(
      halfMonthStartDate,
      halfMonthEndDate
    );

    const { collaborator, collaboratorDayReports } =
      collaboratorDaysReportsWrapper;

    const relevantCollaboratorDaysReports = collaboratorDayReports.filter(
      (dayReport) =>
        relevantPeriodDates.some((validDate) =>
          validDate.isSame(dayReport.date, "day")
        )
    );

    const periodHours = this.calculateShiftHoursInPeriod(
      collaborator,
      relevantCollaboratorDaysReports,
      yearPublicHolidays
    );

    const concludedWeeksReports = collaboratorDayReports.filter((dayReport) =>
      dayReport.date.isSameOrBefore(getPreviousSunday(halfMonthEndDate), "day")
    );

    let tardiness = 0;
    let punctualityBonus = true;

    relevantCollaboratorDaysReports.forEach((dayReport) => {
      const delayMinutes = dayReport.delayMinutes || 0;
      const anticipatedMinutes = 0; // Always zero as per the original code

      if (delayMinutes >= 6 || anticipatedMinutes >= 6) {
        punctualityBonus = false;

        // Calculate tardiness points based on delay/anticipated minutes
        if (delayMinutes > 10 || anticipatedMinutes > 10) tardiness += 1;
        if (delayMinutes > 30 || anticipatedMinutes > 30) tardiness += 1;
        if (delayMinutes > 120 || anticipatedMinutes > 120) tardiness += 1;
      }
    });

    const concludedWeeksHours = this.getHoursByConcludedWeeks(
      collaborator,
      concludedWeeksReports
    );

    return {
      collaborator,
      halfMonthStartDate,
      halfMonthEndDate,
      collaboratorDayReports,
      periodHours,
      concludedWeeksHours,
      tardiness,
      punctualityBonus,
    };
  }

  public getHoursByConcludedWeeks(
    collaborator: ICollaborator,
    collaboratorDaysReports: CollaboratorDayReport[]
  ): ConcludedWeekHours {
    let week = 0;
    const weeklyHours = collaborator.weeklyHours || 48;

    const reportsByWeek = collaboratorDaysReports.reduce((weeks, report) => {
      const weekDay = report.date.day();

      if (weekDay === 1) {
        week++;
      }

      if (!weeks[week]) {
        weeks[week] = [];
      }
      weeks[week].push(report);
      return weeks;
    }, {} as Record<number, CollaboratorDayReport[]>);

    let restWorkedHours = 0;
    let singlePlayWorkedExtraHours = 0;
    let doublePlayWorkedExtraHours = 0;
    let triplePlayWorkedExtraHours = 0;
    let notWorkedHours = 0;

    // Process each week
    Object.values(reportsByWeek).forEach((weekReports) => {
      // Check if there's a rest day, vacation day, or personal leave day
      const hasRestDay = weekReports.some((report) =>
        ["RestDay", "Vacation", "PersonalLeave"].includes(report.workingDayType)
      );

      const {
        workedHours,
        estimatedWorkedHours,
        simulatedAsistanceHours,
        toBeCompensatedHours,
        vacationHours,
        personalLeaveHours,
        justifiedAbsenceByCompanyHours,
        nonComputableHours,
        sickLeaveHours,
        authorizedUnjustifiedAbsenceHours,
        unjustifiedAbsenceHours,
      } = this.calculateShiftHoursInPeriod(collaborator, weekReports);

      const workedOrExcludedHours =
        workedHours +
        nonComputableHours +
        estimatedWorkedHours +
        simulatedAsistanceHours +
        toBeCompensatedHours +
        vacationHours +
        personalLeaveHours +
        justifiedAbsenceByCompanyHours +
        sickLeaveHours +
        authorizedUnjustifiedAbsenceHours +
        unjustifiedAbsenceHours;

      /*
        weekly = 35
        extrahours = 12
      */
      const extraHours = workedOrExcludedHours - weeklyHours;
      if (extraHours < 0) {
        notWorkedHours += extraHours;
      }

      const maxSinglePlayWorkedExtraHours = MAX_WORK_WEEK_LIMIT - weeklyHours; // 48 - 35 = 13
      const addToSinglePlayWorkedExtraHours = Math.max(
        Math.min(extraHours, maxSinglePlayWorkedExtraHours),
        0
      );
      const remainingExtraHours = extraHours - addToSinglePlayWorkedExtraHours;
      const maxDoublePlayWorkedExtraHours =
        MAX_DOUBLE_PLAY_WORK_WEEK_LIMIT - MAX_WORK_WEEK_LIMIT;

      const addToDoublePlayWorkedExtraHours = Math.max(
        Math.min(remainingExtraHours, maxDoublePlayWorkedExtraHours),
        0
      );
      const addToTriplePlayWorkedExtraHours = Math.max(
        remainingExtraHours - addToDoublePlayWorkedExtraHours,
        0
      );

      singlePlayWorkedExtraHours += addToSinglePlayWorkedExtraHours;
      doublePlayWorkedExtraHours += addToDoublePlayWorkedExtraHours;
      triplePlayWorkedExtraHours += addToTriplePlayWorkedExtraHours;

      if (hasRestDay) {
        return;
      }

      // days withoud assigned hours
      const daysWithoutAssignedHours = weekReports.filter(
        (report) => report.assignedHours === 0
      );

      if (daysWithoutAssignedHours.length > 0) {
        const minWorkedHoursReport = daysWithoutAssignedHours.reduce(
          (min, report) => {
            return Math.min(min, report.workedHours);
          },
          Infinity
        );

        restWorkedHours += minWorkedHoursReport;
        return;
      }

      const minWorkedHoursReport = weekReports.reduce((min, report) => {
        return Math.min(min, report.workedHours);
      }, Infinity);

      restWorkedHours += minWorkedHoursReport;
    });

    return {
      restWorkedHours,
      singlePlayWorkedExtraHours,
      doublePlayWorkedExtraHours,
      triplePlayWorkedExtraHours,
      notWorkedHours,
    };
  }

  public calculateShiftHoursInPeriod(
    collaborator: ICollaborator,
    collaboratorDaysReports: CollaboratorDayReport[],
    yearPublicHolidays?: IPublicHolidaysEntity
  ): PeriodHours {
    const { weeklyHours } = collaborator;
    const dailyWorkingHours = weeklyHours ? weeklyHours / 6 : 8;

    /*
      TODO
      globales
        horas totales válidas:
        horas de trabajo esperado:

      asignación de horas
        horas asignadas
      horas que se pagan
        horas trabajadas
        horas estimadas por falta de registro
        horas de asistencia simulada
        horas por fuerza mayor
        horas a reponer
        horas de vacaciones
        horas de asuntos propios

      horas que se pagan al 60%
        horas justificadas por la empresa
      horas que se descuentan o ignoran
        horas no computadas
        horas de reposición
        horas de incapacidad
        horas de falta injustificada autorizada
        horas de falta injustificada

      horas especiales
        horas de trabajo en día festivo
        horas de trabajo en día de descanso
        horas extraordinas simples
        horas extraordinarias dobles
        horas extraordinarias triples
    */

    const countAssignedHoursByWorkingDayType = (
      workingDayType: WorkingDayType
    ) => {
      return collaboratorDaysReports
        .filter((dayReport) => dayReport.workingDayType === workingDayType)
        .reduce((acc, dayReport) => acc + dayReport.assignedHours, 0);
    };

    const estimateHoursByWorkingDayType = (workingDayType: WorkingDayType) => {
      return (
        collaboratorDaysReports.filter(
          (dayReport) => dayReport.workingDayType === workingDayType
        ).length * dailyWorkingHours
      );
    };

    const workedHours = collaboratorDaysReports.reduce(
      (acc, dayReport) => acc + dayReport.workedHours,
      0
    );

    const workedSundayHours = collaboratorDaysReports
      .filter((dayReport) => dayReport.date.day() === 0)
      .reduce((acc, dayReport) => acc + dayReport.workedHours, 0);

    const estimatedWorkedHours =
      countAssignedHoursByWorkingDayType(WorkingDayType.IncompleteRecord) / 2;

    const simulatedAsistanceHours = countAssignedHoursByWorkingDayType(
      WorkingDayType.SimulatedAsistance
    );

    const toBeCompensatedHours = countAssignedHoursByWorkingDayType(
      WorkingDayType.ShiftToBeCompensated
    );

    const vacationHours = estimateHoursByWorkingDayType(
      WorkingDayType.Vacation
    );

    const personalLeaveHours = estimateHoursByWorkingDayType(
      WorkingDayType.PersonalLeave
    );

    const justifiedAbsenceByCompanyHours = countAssignedHoursByWorkingDayType(
      WorkingDayType.JustifiedAbsenceByCompany
    );

    const nonComputableHours = estimateHoursByWorkingDayType(
      WorkingDayType.NonComputableShift
    );

    const compensationHours = countAssignedHoursByWorkingDayType(
      WorkingDayType.CompensationShift
    );

    const sickLeaveHours = countAssignedHoursByWorkingDayType(
      WorkingDayType.SickLeave
    );

    const authorizedUnjustifiedAbsenceHours =
      countAssignedHoursByWorkingDayType(
        WorkingDayType.AuthorizedUnjustifiedAbsence
      );

    const unjustifiedAbsenceHours = countAssignedHoursByWorkingDayType(
      WorkingDayType.UnjustifiedAbsence
    );

    const publicHolidaysHours = yearPublicHolidays
      ? collaboratorDaysReports
          .filter((dayReport) =>
            yearPublicHolidays.publicHolidays.some((publicHoliday) =>
              publicHoliday.isSame(dayReport.date, "day")
            )
          )
          .reduce((acc, dayReport) => acc + dayReport.workedHours, 0)
      : 0;

    // obtain completed weeks

    // check if at least one day is a rest day

    return {
      workedHours,
      estimatedWorkedHours,
      simulatedAsistanceHours,
      toBeCompensatedHours,
      vacationHours,
      personalLeaveHours,
      justifiedAbsenceByCompanyHours,
      nonComputableHours,
      compensationHours,
      sickLeaveHours,
      authorizedUnjustifiedAbsenceHours,
      unjustifiedAbsenceHours,
      publicHolidaysHours,
      workedSundayHours,
    };
  }
}
