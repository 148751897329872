import React, { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";

import { useAppDispatch, useAuthHook } from "../../../../../hooks";
import { SimpleFormikTextField } from "../../../../../components/form/SimpleFormikTextField";
import {
  ApiResponseList,
  ISalaryData,
  ISalaryDataRequest,
  ISalaryDataResponse,
} from "../../../../../shared/types";

import { Table } from "react-bootstrap";
import { salaryDataThunks } from "../../../../../store/thunks";
import { fireSwalError } from "../../../../../helpers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const commonRateSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().optional(),
  rate: Yup.number().required("Rate is required"),
});
const imssRatesSchema = Yup.object().shape({
  sicknessAndMaternity: Yup.object().shape({
    fixedFee: commonRateSchema,
    surplus: commonRateSchema,
    cashBenefits: commonRateSchema,
    pensionersAndBeneficiaries: commonRateSchema,
  }),
  disabilityAndLife: Yup.object().shape({
    disabilityAndLife: commonRateSchema,
  }),
  workRisk: Yup.object().shape({
    workRisk: commonRateSchema,
  }),
  daycareAndSocialBenefits: Yup.object().shape({
    daycareAndSocialBenefits: commonRateSchema,
  }),
  oldAge: Yup.object().shape({
    retirement: commonRateSchema,
    oldAge: Yup.array().of(
      Yup.object().shape({
        umaLimit: Yup.number().required("UMA limit is required"),
        rate: Yup.number().required("Rate is required"),
      })
    ),
  }),
  infonavit: Yup.object().shape({
    infonavit: commonRateSchema,
  }),
});

const isrRateSchema = Yup.object().shape({
  lowerLimit: Yup.number().required("Lower limit is required"),
  upperLimit: Yup.number().required("Upper limit is required"),
  fixedFee: Yup.number().required("Fixed fee is required"),
  rate: Yup.number().required("Rate is required"),
});

const validationSchema = Yup.object().shape({
  year: Yup.number().required("Year is required"),
  minimumWage: Yup.number().required("Minimum wage is required"),
  uma: Yup.number().required("UMA is required"),
  ocupationalRisk: Yup.number().required("Occupational Risk is required"),

  imssEmployerRates: imssRatesSchema,
  imssEmployeeRates: imssRatesSchema,
  halfMonthIsrRates: Yup.array().of(isrRateSchema),
});

export default validationSchema;

type Props = {
  initialValues: ISalaryDataRequest;
};

export const SalaryDataForm = ({ initialValues }: Props) => {
  const { isAdmin } = useAuthHook();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState(initialValues);
  const [formYear, setFormYear] = useState(initialValues.year);

  const handleSubmit = async (values: ISalaryData) => {
    try {
      if (values.id) {
        await dispatch(salaryDataThunks.updateItem(values)).unwrap();
      } else {
        await dispatch(salaryDataThunks.createItem(values)).unwrap();
      }

      navigate("/dashboard/parameters/salary-data");
    } catch (error) {
      console.error("Error while submitting the form:", error);
    }
  };

  console.log(formYear);

  const handleCopyFromLastYear = async () => {
    try {
      const lastYear = formYear - 1;

      const response: ISalaryDataResponse[] = await dispatch(
        salaryDataThunks.fetchItems({
          year: lastYear,
        })
      ).unwrap();

      if (response.length > 0) {
        const lastYearData = response[0];

        setFormValues({
          ...lastYearData,
          year: formYear,
          id: undefined,
        });
      } else {
        fireSwalError("No data found for the selected year");
      }
    } catch (error) {
      fireSwalError("Error copying from last year");
      console.error("Error copying from last year:", error);
    }
  };

  return (
    <Box padding={4}>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" gap={2} alignItems="center">
                <Typography variant="h6" gutterBottom>
                  General Information
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleCopyFromLastYear}
                  disabled={!formYear || formYear.toString().length !== 4}
                >
                  Copy from last year
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="year"
                    label="Year"
                    fullWidth
                    onChange={(e) => {
                      setFormYear(Number(e.target.value));
                      setFieldValue("year", e.target.value);
                    }}
                    value={formYear}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="minimumWage"
                    label="Minimum Wage"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="uma"
                    label="UMA"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="ocupationalRisk"
                    label="Occupational Risk"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="employmentSubsidyLimit"
                    label="Employment Subsidy Limit"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="employmentSubsidyAmount"
                    label="Employment Subsidy Amount"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="maxWorkingHours"
                    label="Max Working Hours"
                    fullWidth
                    type="number"
                  />
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>
                Company Data
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="minimumWageHVP"
                    label="Minimum Wage HVP"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="annualIncreasePercentage"
                    label="Annual Increase Percentage"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="receptionBonus"
                    label="Reception Bonus"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="degreeBonus"
                    label="Degree Bonus"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="trainingSupport"
                    label="Training Support"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="physicalActivitySupport"
                    label="Physical Activity Support"
                    fullWidth
                    type="number"
                  />
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>
                Projection Vars
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="avgMonthlyOvertimeHours"
                    label="Avg Monthly Overtime Hours"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="avgMonthlySundayHours"
                    label="Avg Monthly Sunday Hours"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleFormikTextField
                    name="avgMonthlyHolidayHours"
                    label="Avg Monthly Holiday Hours"
                    fullWidth
                    type="number"
                  />
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>
                IMSS Rates
              </Typography>
              <TableContainer component={Paper} sx={{ padding: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Rate Type</TableCell>
                      <TableCell align="center">Employer Rate</TableCell>
                      <TableCell align="center">Employee Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <SubtitleRow title="Enfermedad y Maternidad" />
                    <RateRow
                      title="Cuota Fija"
                      name="sicknessAndMaternity.fixedFee"
                    />
                    <RateRow
                      title="Excedente"
                      name="sicknessAndMaternity.surplus"
                    />
                    <RateRow
                      title="Prestaciones en dinero"
                      name="sicknessAndMaternity.cashBenefits"
                    />
                    <RateRow
                      title="Prestaciones en especie"
                      name="sicknessAndMaternity.pensionersAndBeneficiaries"
                    />
                    <SubtitleRow title="Invalidez y Vida" />
                    <RateRow
                      title="Invalidez y Vida"
                      name="disabilityAndLife.disabilityAndLife"
                    />
                    <SubtitleRow title="Riesgo de trabajo" />
                    <RateRow
                      title="Riesgo de trabajo"
                      name="workRisk.workRisk"
                    />
                    <SubtitleRow title="Guarderías y prestaciones sociales" />
                    <RateRow
                      title="Guarderías y prestaciones sociales"
                      name="daycareAndSocialBenefits.daycareAndSocialBenefits"
                    />
                    <SubtitleRow title="Cesantía en edad avanzada y vejez" />
                    <RateRow title="Retiro" name="oldAge.retirement" />
                    <TableRow>
                      <TableCell>Cesantía en edad avanzada y vejez</TableCell>
                    </TableRow>

                    <OldAgeRateRow title="Hasta por 1 UMA" index={0} />
                    <OldAgeRateRow title="Hasta por 1.5 UMA" index={1} />
                    <OldAgeRateRow title="Hasta por 2 UMA" index={2} />
                    <OldAgeRateRow title="Hasta por 2.5 UMA" index={3} />
                    <OldAgeRateRow title="Hasta por 3 UMA" index={4} />
                    <OldAgeRateRow title="Hasta por 3.5 UMA" index={5} />
                    <OldAgeRateRow title="Hasta por 4 UMA" index={6} />
                    <OldAgeRateRow title="Más de 4 UMA" index={7} />

                    <SubtitleRow title="Infonavit" />
                    <RateRow title="Infonavit" name="infonavit.infonavit" />
                  </TableBody>
                </Table>
              </TableContainer>

              <Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6">ISR Rates (Half Month)</Typography>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const currentRates = values.halfMonthIsrRates || [];
                      setFieldValue("halfMonthIsrRates", [
                        ...currentRates,
                        { lowerLimit: 0, upperLimit: 0, fixedFee: 0, rate: 0 },
                      ]);
                    }}
                  >
                    Add Rate
                  </Button>
                </Box>
                <TableContainer component={Paper} sx={{ padding: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Lower Limit</TableCell>
                        <TableCell>Upper Limit</TableCell>
                        <TableCell>Fixed Fee</TableCell>
                        <TableCell>Rate (%)</TableCell>
                        <TableCell width="50px"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(values.halfMonthIsrRates || []).map((_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <SimpleFormikTextField
                              name={`halfMonthIsrRates[${index}].lowerLimit`}
                              type="number"
                            />
                          </TableCell>
                          <TableCell>
                            <SimpleFormikTextField
                              name={`halfMonthIsrRates[${index}].upperLimit`}
                              type="number"
                            />
                          </TableCell>
                          <TableCell>
                            <SimpleFormikTextField
                              name={`halfMonthIsrRates[${index}].fixedFee`}
                              type="number"
                            />
                          </TableCell>
                          <TableCell>
                            <SimpleFormikTextField
                              name={`halfMonthIsrRates[${index}].rate`}
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                const newRates = [...values.halfMonthIsrRates];
                                newRates.splice(index, 1);
                                setFieldValue("halfMonthIsrRates", newRates);
                              }}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save Changes
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const SubtitleRow = ({ title }: { title: string }) => (
  <TableRow>
    <TableCell>
      <Typography variant="subtitle1" fontWeight="bold">
        {title}
      </Typography>
    </TableCell>
  </TableRow>
);

const RateRow = ({ title, name }: { title: string; name: string }) => (
  <TableRow>
    <TableCell>{title}</TableCell>
    <TableCell align="center">
      <SimpleFormikTextField name={`imssEmployerRates.${name}.rate`} />
    </TableCell>
    <TableCell align="center">
      <SimpleFormikTextField name={`imssEmployeeRates.${name}.rate`} />
    </TableCell>
  </TableRow>
);

type OldAgeRateRowProps = {
  title: string;
  index: number;
};
const OldAgeRateRow = ({ title, index }: OldAgeRateRowProps) => {
  return (
    <TableRow key={index}>
      <TableCell>
        <Typography fontStyle="italic" paddingLeft={4} fontSize={12}>
          {title}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <SimpleFormikTextField
          name={`imssEmployerRates.oldAge.oldAge[${index}].rate`}
        />
      </TableCell>
      <TableCell align="center">
        <SimpleFormikTextField
          name={`imssEmployeeRates.oldAge.oldAge[${index}].rate`}
        />
      </TableCell>
    </TableRow>
  );
};
