import React from "react";
import { Formik, Field, Form } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import {
  TimeOffRequestRequest,
  TimeOffStatus,
  TimeOffType,
} from "../../../../shared/types";
import { useAppSelector, useTimeOffRequests } from "../../../../hooks";
import { CollaboratorSelect, FormikOptionSelect } from "../../../../components";
import { CustomSelect } from "../../../../components/inputs/CustomSelect";
import {
  getOptionsFromEnum,
  getOptionsFromEnumNew,
} from "../../../../helpers/options.helpers";
import { DayPicker } from "react-day-picker";

export const TimeOffRequestCreate: React.FC = () => {
  const { uid } = useAppSelector((state) => state.authReducer.user!);
  const initialValues: TimeOffRequestRequest = {
    collaboratorNote: "",
    requestedDays: [],
    timeOffType: TimeOffType.Vacation,
    requestedAt: new Date().toISOString(),
    collaborator: uid!,
    status: TimeOffStatus.Pending,
  };

  const { createTimeOffRequest } = useTimeOffRequests();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        console.log({ values });
        if (
          values.status === TimeOffStatus.Approved ||
          values.status === TimeOffStatus.Canceled
        ) {
          values.approvedAt = new Date().toISOString();
          values.approvedBy = uid!;
          // TODO: Add your API call here
        } else {
          values.approvedAt = undefined;
          values.approvedBy = undefined;
        }
        createTimeOffRequest(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Box sx={{ maxWidth: 600, mx: "auto", p: 3 }}>
            <Stack spacing={3}>
              <FormikOptionSelect
                label="Time Off Type"
                name="timeOffType"
                options={getOptionsFromEnumNew(TimeOffType)}
              />

              <FormikOptionSelect
                label="Status"
                name="status"
                options={getOptionsFromEnum(TimeOffStatus)}
              />

              <CollaboratorSelect
                selectedCollaborator={values.collaborator}
                handleSelectChange={(e) =>
                  setFieldValue("collaborator", e.target.value)
                }
                shownCollaborators="active"
                sx={{ height: "100%" }}
              />

              <Field
                as={DayPicker}
                name="requestedDays"
                selected={values.requestedDays}
                onSelect={(dates: Date[]) => {
                  setFieldValue("requestedDays", dates);
                }}
                isMultiSelect
                dateFormat="yyyy-MM-dd"
                mode="multiple"
              />

              <Field
                as={TextField}
                name="collaboratorNote"
                label="Collaborator Note"
                multiline
                rows={4}
                fullWidth
              />

              <Field
                as={TextField}
                name="managerNote"
                label="Manager Note"
                multiline
                rows={4}
                fullWidth
              />

              <Button type="submit" variant="contained" color="primary">
                Submit Request
              </Button>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
