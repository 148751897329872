import { useCallback, useMemo } from "react";
import { useAppSelector } from "./useAppSelector";
import { ICollaborator } from "../shared/types";
import dayjs from "dayjs";

export const useJobsHook = () => {
  const { jobs } = useAppSelector((state) => state.jobs);
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const sortCollaboratorsByJob = useCallback(
    (collaboratorsList: ICollaborator[]) => {
      return [...collaboratorsList].sort((a, b) => {
        // If either jobId is falsy, place it at the end
        if (!a.jobId) return 1;
        if (!b.jobId) return -1;

        const jobA = jobs.find((job) => job.id === a.jobId);
        const jobB = jobs.find((job) => job.id === b.jobId);
        return (
          (jobA?.sortingOrder ?? Infinity) - (jobB?.sortingOrder ?? Infinity)
        );
      });
    },
    [jobs]
  );

  const sortedCollaboratorsByJob = useMemo(
    () => sortCollaboratorsByJob(collaborators),
    [collaborators, sortCollaboratorsByJob]
  );

  const sortedCollaboratorIdsByJob = useMemo(
    () =>
      sortCollaboratorsByJob(collaborators).map(
        (collaborator) => collaborator.id
      ),
    [collaborators, sortCollaboratorsByJob]
  );

  const sortedShiftCollaboratorsByJob = useMemo(
    () =>
      sortCollaboratorsByJob(
        collaborators.filter((collaborator) => collaborator.coverShift)
      ),
    [collaborators, sortCollaboratorsByJob]
  );

  const getJobTitleById = useCallback(
    (id: string) => {
      return jobs.find((job) => job.id === id)?.title;
    },
    [jobs]
  );

  const getJobTitleByCollaboratorId = useCallback(
    (id: string) => {
      const collaborator = collaborators.find(
        (collaborator) => collaborator.id === id
      );
      return collaborator?.jobId ? getJobTitleById(collaborator.jobId) : null;
    },
    [collaborators, getJobTitleById]
  );

  const collaboratorsWithShifts = useCallback(
    (date: Date) => {
      const filteredCollaborators = collaborators.filter((collaborator) => {
        const { coverShift, startDate, endDate } = collaborator;

        const isWorking =
          dayjs(startDate).isBefore(dayjs(date), "day") &&
          (endDate ? dayjs(endDate).isAfter(dayjs(date), "day") : true);

        return coverShift && isWorking;
      });

      return sortCollaboratorsByJob(filteredCollaborators);
    },
    [collaborators, sortedShiftCollaboratorsByJob]
  );

  return {
    sortedCollaboratorsByJob,
    sortedShiftCollaboratorsByJob,
    getJobTitleById,
    getJobTitleByCollaboratorId,
    sortCollaboratorsByJob,
    collaboratorsWithShifts,
    jobs,
    sortedCollaboratorIdsByJob,
  };
};
