import { BaseService } from "./base.service";
import {
  EmploymentFormValues,
  EmploymentRequest,
  EmploymentResponse,
  ICollaborator,
  IJob,
} from "../shared/types";
import dayjs from "dayjs";
import {
  COMMISION_BONUS_PERCENTAGE_PER_SEMESTER,
  DEGREE_BONUS,
  MAX_LEGAL_WEEKLY_HOURS,
  SENIORITY_BONUS_PERCENTAGE_PER_YEAR,
} from "../shared";

const API_URL = "/employments";
export class EmploymentService extends BaseService<
  EmploymentResponse,
  EmploymentRequest
> {
  constructor() {
    super(API_URL);
  }

  public transformResponseToFormValues(
    response: EmploymentResponse
  ): EmploymentFormValues {
    return {
      ...response,
      employmentStartDate: dayjs(response.employmentStartDate),
      employmentEndDate: response.employmentEndDate
        ? dayjs(response.employmentEndDate)
        : undefined,
    };
  }

  public transformFormValuesToRequest(
    formValues: EmploymentFormValues
  ): EmploymentRequest {
    return {
      ...formValues,
      employmentStartDate: formValues.employmentStartDate.toISOString(),
      employmentEndDate: formValues.employmentEndDate?.toISOString(),
      createdAt:
        formValues.createdAt && new Date(formValues.createdAt).toISOString(),
      updatedAt:
        formValues.updatedAt && new Date(formValues.updatedAt).toISOString(),
    };
  }

  public recalculateEmployment({
    job,
    collaborator,
    employment,
  }: RecalculateEmploymentParams) {
    const { baseIncome, minimumIncome } = job;
    const { startDate, degree } = collaborator;
    const { weeklyHours, additionalFixedIncome } = employment;

    const workedYears = dayjs().diff(dayjs(startDate), "year");
    const workedSemesters = Math.floor(
      dayjs().diff(dayjs(startDate), "quarter") / 2
    );
    const seniorityBonusPercentage = Number(
      (workedYears * SENIORITY_BONUS_PERCENTAGE_PER_YEAR).toFixed(4)
    );
    const comissionBonusPercentage = Number(
      (workedSemesters * COMMISION_BONUS_PERCENTAGE_PER_SEMESTER).toFixed(4)
    );

    const hoursRatio = weeklyHours / MAX_LEGAL_WEEKLY_HOURS;

    const fixedIncomeByPosition = Number(
      (baseIncome * (1 + seniorityBonusPercentage) * hoursRatio).toFixed(4)
    );

    const minimumOrdinaryIncome = minimumIncome * hoursRatio;

    const degreeBonus = degree ? DEGREE_BONUS[degree] * hoursRatio : 0;

    const fixedIncome =
      Number(fixedIncomeByPosition) + Number(additionalFixedIncome);

    const result = {
      ...employment,
      seniorityBonusPercentage,
      comissionBonusPercentage,
      fixedIncomeByPosition,
      minimumOrdinaryIncome,
      degreeBonus,
      fixedIncome,
    };

    return result;
  }

  public async getPreviousEmployment(collaboratorId: string) {
    const employments = await this.getAll({
      collaboratorId,
      sort_by: "employmentStartDate",
      direction: "desc",
    });
    const employment = employments.data[0];
    if (!employment) return null;
    return this.transformResponseToFormValues(employment);
  }
}

type RecalculateEmploymentParams = {
  employment: EmploymentFormValues;
  job: IJob;
  collaborator: ICollaborator;
};
