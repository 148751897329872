import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ISalaryData,
  ISalaryDataResponse,
} from "../../../../../../shared/types";
import { SalaryDataService } from "../../../../../../services";
import { SalaryDataForm } from "../../components/SalaryDataForm";
import { CircularProgress } from "@mui/material";

export const EditSalaryData = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [initialValues, setInitialValues] = useState<ISalaryDataResponse>();

  useEffect(() => {
    if (id) {
      const fetchById = async () => {
        try {
          const service = new SalaryDataService();

          const resource = await service.getById(id);

          setInitialValues(resource.data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchById();
    }
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!initialValues) {
    return <div>SalaryData not found</div>;
  }

  return <SalaryDataForm initialValues={initialValues} />;
};
