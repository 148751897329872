import React from "react";
import { SalaryDataForm } from "../components/SalaryDataForm";
import { ISalaryData, ISalaryDataRequest } from "../../../../../shared/types";

export const NewSalaryData = () => {
  const initialValues: ISalaryDataRequest = {
    year: 2024,
    minimumWage: 248.93,
    uma: 108.57,
    ocupationalRisk: 0.005436,
    minimumWageHVP: 0,
    annualIncreasePercentage: 0,
    employmentSubsidyLimit: 0,
    employmentSubsidyAmount: 0,
    maxWorkingHours: 48,
    receptionBonus: 0,
    degreeBonus: 0,
    trainingSupport: 0,
    physicalActivitySupport: 0,
    avgMonthlyOvertimeHours: 0,
    avgMonthlySundayHours: 0,
    avgMonthlyHolidayHours: 0,
    imssEmployerRates: {
      sicknessAndMaternity: {
        fixedFee: {
          name: "Cuota fija",
          description: "Base: UMA. 106.I",
          rate: 0.204,
        },
        surplus: {
          name: "Excedente",
          description: "Base: Diferencia entre SBC y 3 veces la UMA. 106.II",
          rate: 0.011,
        },
        cashBenefits: {
          name: "Prestaciones en dinero",
          description: "Base: SBC. 107",
          rate: 0.007,
        },
        pensionersAndBeneficiaries: {
          name: "Pensionados y beneficiarios",
          description: "Base: SBC. ???",
          rate: 0.0105,
        },
      },
      disabilityAndLife: {
        disabilityAndLife: {
          name: "Invalidez y vida",
          description: "Base: SBC. ???",
          rate: 0.0175,
        },
      },
      workRisk: {
        workRisk: {
          name: "Riesgos de trabajo",
          description: "Base: SBC. ???",
          rate: 0.005436,
        },
      },
      daycareAndSocialBenefits: {
        daycareAndSocialBenefits: {
          name: "Guarderías y prestaciones sociales",
          description: "Base: SBC. ???",
          rate: 0.01,
        },
      },
      oldAge: {
        retirement: {
          name: "Retiro",
          description: "Base: SBC. ???",
          rate: 0.02,
        },
        oldAge: [
          {
            umaLimit: 1.0,
            rate: 0.0315,
          },
          {
            umaLimit: 1.5,
            rate: 0.03544,
          },
          {
            umaLimit: 2.0,
            rate: 0.04,
          },
          {
            umaLimit: 2.5,
            rate: 0.04353,
          },
          {
            umaLimit: 3.0,
            rate: 0.04588,
          },
          {
            umaLimit: 3.5,
            rate: 0.04756,
          },
          {
            umaLimit: 4.0,
            rate: 0.04882,
          },
          {
            umaLimit: 1000,
            rate: 0.05331,
          },
        ],
      },
      infonavit: {
        infonavit: {
          name: "Infonavit",
          description: "Base: SBC. ???",
          rate: 0.05,
        },
      },
    },
    imssEmployeeRates: {
      sicknessAndMaternity: {
        fixedFee: {
          name: "Cuota fija",
          description: "Base: UMA. 106.I",
          rate: 0.0,
        },
        surplus: {
          name: "Excedente",
          description: "Base: Diferencia entre SBC y 3 veces la UMA. 106.II",
          rate: 0.004,
        },
        cashBenefits: {
          name: "Prestaciones en dinero",
          description: "Base: SBC. 107",
          rate: 0.007,
        },
        pensionersAndBeneficiaries: {
          name: "Pensionados y beneficiarios",
          description: "Base: SBC. ???",
          rate: 0.0025,
        },
      },
      disabilityAndLife: {
        disabilityAndLife: {
          name: "Invalidez y vida",
          description: "Base: SBC. ???",
          rate: 0.00625,
        },
      },
      workRisk: {
        workRisk: {
          name: "Riesgos de trabajo",
          description: "Base: SBC. ???",
          rate: 0.0,
        },
      },
      daycareAndSocialBenefits: {
        daycareAndSocialBenefits: {
          name: "Guarderías y prestaciones sociales",
          description: "Base: SBC. ???",
          rate: 0.0,
        },
      },
      oldAge: {
        retirement: {
          name: "Retiro",
          description: "Base: SBC. ???",
          rate: 0.0,
        },
        oldAge: [
          {
            umaLimit: 1.0,
            rate: 0.01125,
          },
          {
            umaLimit: 1.5,
            rate: 0.01125,
          },
          {
            umaLimit: 2.0,
            rate: 0.01125,
          },
          {
            umaLimit: 2.5,
            rate: 0.01125,
          },
          {
            umaLimit: 3.0,
            rate: 0.01125,
          },
          {
            umaLimit: 3.5,
            rate: 0.01125,
          },
          {
            umaLimit: 4.0,
            rate: 0.01125,
          },
          {
            umaLimit: 1000,
            rate: 0.01125,
          },
        ],
      },
      infonavit: {
        infonavit: {
          name: "Infonavit",
          description: "Base: SBC. ???",
          rate: 0.0,
        },
      },
    },
    halfMonthIsrRates: [
      { lowerLimit: 0.01, upperLimit: 368.1, fixedFee: 0.0, rate: 0.0192 },
      { lowerLimit: 368.11, upperLimit: 3124.35, fixedFee: 7.05, rate: 0.064 },
      {
        lowerLimit: 3124.36,
        upperLimit: 5490.75,
        fixedFee: 183.45,
        rate: 0.1088,
      },
      { lowerLimit: 5490.76, upperLimit: 6382.8, fixedFee: 441.0, rate: 0.16 },
      {
        lowerLimit: 6382.81,
        upperLimit: 7641.9,
        fixedFee: 583.65,
        rate: 0.1792,
      },
      {
        lowerLimit: 7641.91,
        upperLimit: 15412.8,
        fixedFee: 809.25,
        rate: 0.2136,
      },
      {
        lowerLimit: 15412.81,
        upperLimit: 24292.65,
        fixedFee: 2469.15,
        rate: 0.2352,
      },
      {
        lowerLimit: 24292.66,
        upperLimit: 46378.5,
        fixedFee: 4557.75,
        rate: 0.3,
      },
      {
        lowerLimit: 46378.51,
        upperLimit: 61838.1,
        fixedFee: 11183.4,
        rate: 0.32,
      },
      {
        lowerLimit: 61838.11,
        upperLimit: 185514.3,
        fixedFee: 16130.55,
        rate: 0.34,
      },
      {
        lowerLimit: 185514.31,
        upperLimit: 10000000,
        fixedFee: 58180.35,
        rate: 0.35,
      },
    ],
  };

  return <SalaryDataForm initialValues={initialValues} />;
};
