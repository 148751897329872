import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import { jobThunks } from "../../../../store/thunks/job.thunks";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const JobList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(jobThunks.fetchItems());
  }, [dispatch]);

  const handleDelete = (id: string) => {
    dispatch(jobThunks.deleteItem(id));
  };

  const handleEdit = (id: string) => {
    navigate(`/dashboard/parameters/jobs/edit/${id}`);
  };

  const handleView = (id: string) => {
    navigate(`/dashboard/parameters/jobs/${id}`);
  };

  const { jobs = [], isLoading } = useAppSelector((state) => state.jobs);

  const sortedJobs = React.useMemo(() => {
    return [...jobs].sort((a, b) => a.sortingOrder - b.sortingOrder);
  }, [jobs]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Job List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sorting Order</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Base Income</TableCell>
              <TableCell>Hourly Rate</TableCell>
              <TableCell>Minimum Income</TableCell>
              <TableCell>Annual Raise Percent</TableCell>
              <TableCell>Quarterly Comission Raise Percent</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Income Multiplier</TableCell>
              <TableCell>Expected Comissions Percentage</TableCell>
              <TableCell>Expected Minimum Income</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedJobs.map((job) => (
              <TableRow key={job.id}>
                <TableCell>{job.sortingOrder}</TableCell>
                <TableCell>{job.title}</TableCell>
                <TableCell>{job.description || "No description"}</TableCell>
                <TableCell>{job.paymentType}</TableCell>
                <TableCell>
                  {job.baseIncome ? `$${job.baseIncome}` : "N/A"}
                </TableCell>
                <TableCell>
                  {job.hourlyRate ? `$${job.hourlyRate}` : "N/A"}
                </TableCell>
                <TableCell>
                  {job.minimumIncome ? `$${job.minimumIncome}` : "N/A"}
                </TableCell>
                <TableCell>{job.annualRaisePercent}%</TableCell>
                <TableCell>{job.quarterlyCommissionRaisePercent}%</TableCell>
                <TableCell>{job.active ? "Yes" : "No"}</TableCell>
                <TableCell>{job.incomeMultiplier}</TableCell>
                <TableCell>{job.expectedCommissionsPercentage}%</TableCell>
                <TableCell>
                  {job.expectedMinimumIncome
                    ? `$${job.expectedMinimumIncome}`
                    : "N/A"}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleView(job.id!)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => handleEdit(job.id!)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(job.id!)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
