import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {
  TimeOffRequest,
  TimeOffType,
  timeOffRequestTypeDescriptions,
} from "../../../../shared/types/time-off-request.types";
import { useTimeOffRequests } from "../../../../hooks/useTimeOffRequestsHook";
import { getFormattedMxDate } from "../../../../helpers/dateHelpers";
import { fireSwalError } from "../../../../helpers";
import { validateTimeOffRequest } from "../../../../shared/helpers/time-off-requests.helpers";

type TimeOffRequestKeys = keyof TimeOffRequest;

const validationSchema: Yup.SchemaOf<TimeOffRequest> = Yup.object().shape({
  requestedDays: Yup.array()
    .of(Yup.date().required("Requested day is required"))
    .required("Requested days are required"),
  timeOffType: Yup.string().required("Time off type is required"),
} as Record<TimeOffRequestKeys, any>);

const getFooterContent = (values: TimeOffRequest) => {
  return values.requestedDays.length
    ? `You have picked: ${values.requestedDays
        .map(getFormattedMxDate)
        .join(", ")}`
    : "Pick a day";
};

type TimeOffRequestFormProps = {
  handleSelectedDatesChange: (dates: Date[]) => void;
  handleMonthChange: (date: Date) => void;
  setTimeOffType: React.Dispatch<React.SetStateAction<TimeOffType>>;
};
export const TimeOffRequestForm: React.FC<TimeOffRequestFormProps> = ({
  handleSelectedDatesChange,
  handleMonthChange,
  setTimeOffType,
}) => {
  const [open, setOpen] = useState(false);

  const { startSavingTimeOffRequest } = useTimeOffRequests();

  const initialValues: TimeOffRequest = {
    collaboratorNote: "",
    requestedDays: [],
    timeOffType: TimeOffType.Vacation,
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(
    (values: TimeOffRequest) => {
      const validationResult = validateTimeOffRequest(values);

      if (validationResult.error) {
        return fireSwalError(validationResult.error);
      }

      startSavingTimeOffRequest(values);
    },
    [startSavingTimeOffRequest]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue, values, handleChange }) => (
        <Form>
          <Paper elevation={3}>
            <Box p={3} mb={2}>
              <Typography variant="h6" gutterBottom>
                Time Off Request Form
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <FormLabel id="timeOffType-label">
                      Time Off Type
                      <Tooltip title="Click for more information">
                        <IconButton onClick={handleOpen}>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </FormLabel>
                    <Field
                      as={Select}
                      labelId="timeOffType-label"
                      id="timeOffType"
                      name="timeOffType"
                      error={Boolean(errors.timeOffType)}
                      input={<OutlinedInput label="Time Off Type" />}
                      onChange={(
                        event: React.ChangeEvent<{ value: TimeOffType }>
                      ) => {
                        console.log({ event });
                        setFieldValue("timeOffType", event.target.value);
                        setTimeOffType(event.target.value);
                      }}
                    >
                      {Object.entries(TimeOffType).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Field
                      as={DayPicker}
                      name="requestedDays"
                      selected={values.requestedDays}
                      onSelect={(dates: Date[]) => {
                        handleSelectedDatesChange(dates);
                        setFieldValue("requestedDays", dates);
                      }}
                      isMultiSelect
                      dateFormat="yyyy-MM-dd"
                      mode="multiple"
                      footer={getFooterContent(values)}
                      onMonthChange={handleMonthChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    id="collaboratorNote"
                    name="collaboratorNote"
                    label="Collaborator Note"
                    error={Boolean(errors.collaboratorNote)}
                    helperText={errors.collaboratorNote}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" id="modal-title">
                Time Off Types
              </Typography>
              {Object.entries(TimeOffType).map(([key, value]) => (
                <Typography variant="body1" key={key}>
                  <strong>{value}:</strong>{" "}
                  {
                    timeOffRequestTypeDescriptions[
                      value as keyof typeof timeOffRequestTypeDescriptions
                    ]
                  }
                </Typography>
              ))}
            </Box>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
