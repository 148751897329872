import React from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";

type FormikFieldProps = {
  name: string;
  numberType?: "currency" | "percentage";
  readOnly?: boolean;
} & Omit<TextFieldProps, "name">;

export const FormikField: React.FC<FormikFieldProps> = ({
  name,
  numberType,
  readOnly,
  ...props
}) => {
  const [field, meta] = useField(name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (numberType === "percentage") {
      field.onChange({
        ...e,
        target: {
          ...e.target,
          value: String(Number(e.target.value) / 100),
          name,
        },
      });
    } else {
      field.onChange(e);
    }
  };

  const displayValue =
    numberType === "percentage" && field.value
      ? String(Number(field.value) * 100)
      : field.value;

  return (
    <TextField
      {...field}
      {...props}
      value={displayValue}
      onChange={handleChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{
        ...props.InputProps,
        readOnly,
        sx: {
          ...props.InputProps?.sx,
          backgroundColor: readOnly ? "#e3f2fd" : "inherit",
        },
        startAdornment:
          numberType === "currency" ? (
            <InputAdornment position="start">$</InputAdornment>
          ) : undefined,
        endAdornment:
          numberType === "percentage" ? (
            <InputAdornment position="end">%</InputAdornment>
          ) : undefined,
      }}
    />
  );
};
