import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  Button,
  Box,
} from "@mui/material";
import { ISalaryData } from "../../../../../shared/types";
import { ImssRatesData } from "./ImssRatesData";
import { useNavigate } from "react-router-dom";
import { fireSwalError } from "../../../../../helpers";
import { IsrRatesData } from "./IsrRatesData";

type Props = {
  data: ISalaryData;
};
export const WageDataCard = ({ data }: Props) => {
  const navigate = useNavigate();
  const {
    year,
    minimumWage,
    uma,
    ocupationalRisk,
    minimumWageHVP,
    annualIncreasePercentage,
    maxWorkingHours,
    receptionBonus,
    degreeBonus,
    trainingSupport,
    physicalActivitySupport,
    avgMonthlyOvertimeHours,
    avgMonthlySundayHours,
    avgMonthlyHolidayHours,
    employmentSubsidyLimit,
    employmentSubsidyAmount,
  } = data;

  return (
    <Card>
      <CardContent>
        {/* General Information */}
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Typography variant="h4" gutterBottom>
            Wage Data for {year}
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              navigate(`/dashboard/parameters/salary-data/edit/${data.id}`)
            }
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => fireSwalError("Is not possible to delete")}
          >
            Delete
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Minimum Wage</Typography>
            <Typography variant="body1">{`$${minimumWage.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Minimum Wage HVP</Typography>
            <Typography variant="body1">{`$${minimumWageHVP?.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">UMA</Typography>
            <Typography variant="body1">{`$${uma.toFixed(2)}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Occupational Risk</Typography>
            <Typography variant="body1">{`${(ocupationalRisk * 100).toFixed(
              2
            )}%`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Annual Increase</Typography>
            <Typography variant="body1">{`${annualIncreasePercentage.toFixed(
              2
            )}%`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Max Working Hours</Typography>
            <Typography variant="body1">{`${maxWorkingHours.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Training Support</Typography>
            <Typography variant="body1">{`${trainingSupport.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Physical Activity Support
            </Typography>
            <Typography variant="body1">{`${physicalActivitySupport.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Avg Monthly Overtime Hours
            </Typography>
            <Typography variant="body1">{`${avgMonthlyOvertimeHours.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Avg Monthly Sunday Hours
            </Typography>
            <Typography variant="body1">{`${avgMonthlySundayHours.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Avg Monthly Holiday Hours
            </Typography>
            <Typography variant="body1">{`${avgMonthlyHolidayHours.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Employment Subsidy Limit
            </Typography>
            <Typography variant="body1">{`${employmentSubsidyLimit.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Employment Subsidy Amount
            </Typography>
            <Typography variant="body1">{`${employmentSubsidyAmount.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Reception Bonus</Typography>
            <Typography variant="body1">{`${receptionBonus.toFixed(
              2
            )}`}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
        <ImssRatesData salaryData={data} />
        <IsrRatesData salaryData={data} />
      </CardContent>
    </Card>
  );
};
