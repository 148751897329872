import { useCallback, useMemo } from "react";
import { useAppSelector } from "./useAppSelector";
import { ICollaborator } from "../shared/types/collaborators.types";
import { useNavigate } from "react-router-dom";
import {
  checkManagerAuthorization,
  checkOwnerOrManagerAuthorization,
} from "../shared/helpers";
import { collaboratorThunks } from "../store/thunks";
import { useAppDispatch } from "./useAppDispatch";
import { getCollaboratorThumbailUrl } from "../helpers";

export const useCollaboratorHook = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const { user } = useAppSelector((state) => state.authReducer);

  const collaboratorsWithShifts = useMemo(() => {
    return collaborators.filter((c) => c.coverShift);
  }, [collaborators]);

  const uid = user?.uid;

  const getCollaboratorById = useCallback(
    (id: string): ICollaborator | undefined => {
      return collaborators.find((c: ICollaborator) => c.id === id);
    },
    [collaborators]
  );

  const getCollaboratorCodeById = useCallback(
    (id: string) => {
      return getCollaboratorById(id)?.col_code;
    },
    [getCollaboratorById]
  );

  const getCurrentCollaborator = useCallback(() => {
    if (!uid) return undefined;
    const collaborator = getCollaboratorById(uid);

    return collaborator;
  }, [getCollaboratorById, uid]);

  const getCurrentCollaboratorCode = useCallback(() => {
    return getCurrentCollaborator()?.col_code;
  }, [getCurrentCollaborator]);

  const getCurrentCollaboratorId = useCallback(() => {
    return getCurrentCollaborator()?.id;
  }, [getCurrentCollaborator]);

  const getCurrentCollaboratorRole = useCallback(() => {
    return getCurrentCollaborator()?.role;
  }, [getCurrentCollaborator]);

  // Directly compute the role
  const currentCollaboratorRole = useMemo(() => {
    return getCurrentCollaborator()?.role;
  }, [getCurrentCollaborator]);

  const activeCollaborators = useMemo(() => {
    return collaborators.filter((c: ICollaborator) => c.isActive);
  }, [collaborators]);

  const activeCollaboratorsIds = useMemo(() => {
    return activeCollaborators.map((c: ICollaborator) => c.id);
  }, [activeCollaborators]);

  const handleEditCollaborator = useCallback(
    async (id: string) => {
      if (await checkOwnerOrManagerAuthorization(user?.role, user?.uid!, id)) {
        navigate(`/dashboard/collaborators/edit/${id}`);
      }
    },
    [navigate, user]
  );

  const handleDeleteCollaborator = useCallback(
    async (id: string) => {
      if (await checkManagerAuthorization(user?.role)) {
        dispatch(collaboratorThunks.deleteItem(id));
      }
    },
    [dispatch, user?.role]
  );

  const getCollaboratorByCode = useCallback(
    (code: string) => {
      return collaborators.find((c: ICollaborator) => c.col_code === code);
    },
    [collaborators]
  );

  const getCollaboratorThumbnailUrlByCollabortorId = useCallback(
    (collaboratorId: string) => {
      const collaborator = getCollaboratorById(collaboratorId);
      return getCollaboratorThumbailUrl(collaborator!);
    },
    [getCollaboratorById]
  );

  return {
    collaborators,
    collaboratorsWithShifts,
    getCollaboratorById,
    getCollaboratorCodeById,
    getCurrentCollaborator,
    getCurrentCollaboratorCode,
    getCurrentCollaboratorId,
    getCurrentCollaboratorRole,
    currentCollaboratorRole,
    activeCollaborators,
    handleEditCollaborator,
    handleDeleteCollaborator,
    getCollaboratorByCode,
    getCollaboratorThumbnailUrlByCollabortorId,
    activeCollaboratorsIds,
  };
};
