import { SalaryDataService } from "../../services";
import { createCRUDThunks } from "../../shared/helpers";
import {
  ISalaryData,
  ISalaryDataRequest,
  ISalaryDataResponse,
} from "../../shared/types";

const service = new SalaryDataService();
export const salaryDataThunks = createCRUDThunks<
  ISalaryDataResponse,
  ISalaryDataRequest
>({
  service,
  resourceName: "salary-data",
});
