import axios from "axios";
import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";
import {
  ApiResponseCreate,
  ApiResponseDelete,
  ApiResponseList,
  ApiResponseOne,
  ApiResponseUpdate,
  AxiosParams,
} from "../shared/types";

export class BaseService<RES, REQ = RES> {
  protected API_URL: string;

  constructor(API_URL: string) {
    this.API_URL = API_URL;
  }

  async getAll(params?: AxiosParams): Promise<ApiResponseList<RES>> {
    const response = await hvpAuthenticatedApi.get<ApiResponseList<RES>>(
      this.API_URL,
      { params }
    );
    return response.data;
  }

  async getById(id: string): Promise<ApiResponseOne<RES>> {
    const response = await hvpAuthenticatedApi.get<ApiResponseOne<RES>>(
      `${this.API_URL}/${id}`
    );
    return response.data;
  }

  async create(resource: REQ): Promise<ApiResponseCreate<RES>> {
    try {
      const response = await hvpAuthenticatedApi.post<ApiResponseCreate<RES>>(
        this.API_URL,
        resource
      );
      return response.data;
    } catch (error) {
      // Let component handle the UI feedback, but add context
      if (axios.isAxiosError(error)) {
        // API error (400, 500, etc)
        throw new Error(
          `${error.response?.data?.message}: ${error.response?.data?.detail}` ||
            "Error de servidor"
        );
      }
      // Network/other errors
      throw new Error("Error de conexión");
    }
  }

  async update(id: string, resource: REQ): Promise<ApiResponseUpdate<RES>> {
    const response = await hvpAuthenticatedApi.patch<ApiResponseUpdate<RES>>(
      `${this.API_URL}/${id}`,
      resource
    );
    return response.data;
  }

  async delete(id: string): Promise<ApiResponseDelete> {
    const response = await hvpAuthenticatedApi.delete<ApiResponseDelete>(
      `${this.API_URL}/${id}`
    );
    return response.data;
  }
}
