import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { ISalaryData } from "../../../../../shared/types";

type Props = {
  salaryData: ISalaryData;
};

export const IsrRatesData = ({ salaryData }: Props) => {
  const { halfMonthIsrRates } = salaryData;

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(amount);
  };

  const formatPercentage = (rate: number) => {
    return `${(rate * 100).toFixed(2)}%`;
  };

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">ISR Tax Brackets (Half Month)</Typography>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Lower Limit</TableCell>
            <TableCell>Upper Limit</TableCell>
            <TableCell>Fixed Fee</TableCell>
            <TableCell>Rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {halfMonthIsrRates.map((bracket, index) => (
            <TableRow key={index}>
              <TableCell>{formatCurrency(bracket.lowerLimit)}</TableCell>
              <TableCell>{formatCurrency(bracket.upperLimit)}</TableCell>
              <TableCell>{formatCurrency(bracket.fixedFee)}</TableCell>
              <TableCell>{formatPercentage(bracket.rate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
