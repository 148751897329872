import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  EmploymentsIndex,
  EmploymentNew,
  EmploymentDetails,
  EmploymentEdit,
  EmploymentCopy,
} from "./";

export const EmploymentsRouter = () => {
  return (
    <Routes>
      <Route index element={<EmploymentsIndex />} />
      <Route path="new" element={<EmploymentNew />} />
      <Route path=":id" element={<EmploymentDetails />} />
      <Route path="edit/:id" element={<EmploymentEdit />} />
      <Route path="copy/:id" element={<EmploymentCopy />} />
      {/* <Route path="edit" element={<SimplifiedBranchCashReconciliationEdit />} />
      {/* <Route path="edit" element={<SimplifiedBranchCashReconciliationEdit />} />
      <Route path="*" element={<SimplifiedBranchCashReconciliationIndex />} /> */}
    </Routes>
  );
};
