import { Degree } from "../enums";

export const SENIORITY_BONUS_PERCENTAGE_PER_YEAR = 0.025;
export const COMMISION_BONUS_PERCENTAGE_PER_SEMESTER = 0.05;
export const MAX_LEGAL_WEEKLY_HOURS = 48;
export const DEGREE_BONUS = {
  [Degree.HighSchool]: 0,
  [Degree.UniversityStudent]: 0,
  [Degree.BachelorComplete]: 0,
  [Degree.Graduated]: 300,
  [Degree.Masters]: 600,
  [Degree.Doctorate]: 900,
  [Degree.Other]: 0,
};
