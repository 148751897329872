import { Dayjs } from "dayjs";
import { IAttendanceRecordEntityDayjs } from "./attendance-record.types";
import {
  TimeOffRequestDayjsEntity,
  TimeOffType,
} from "./time-off-request.types";
import { CollaboratorDayShiftDayjs } from "./week-shift.types";
import { ICollaborator } from "./collaborators.types";

// todo: redo this
export interface CollaboratorAttendanceReportResponse {
  collaboratorId: string;
  startDate: Date; // half month start date
  endDate: Date; // half month end date
  collaboratorDayReports: CollaboratorDayReportResponse[];
  periodHours: PeriodHours;
  concludedWeeksHours: ConcludedWeekHours;
  tardiness: number;
  punctualityBonus: boolean;
  collaborator?: {
    fullName: string;
    profilePicture?: string;
  };
}

export interface CollaboratorDayReportResponse {
  date: string;
  shiftStart?: string;
  shiftEnd?: string;
  attendanceStart?: string;
  attendanceEnd?: string;
  timeOffRequestType?: TimeOffType;
  workingDayType: WorkingDayType;
  assignedHours: number;
  workedHours: number;
  extraHours: number;
  delayMinutes: number;
  anticipatedMinutes: number;
}

// todo: previous

export interface CollaboratorAttendanceData {
  collaborator: ICollaborator;
  dayShifts: CollaboratorDayShiftDayjs[];
  timeoffRequests: TimeOffRequestDayjsEntity[];
  attendanceRecords: IAttendanceRecordEntityDayjs[];
}

export interface DayReportData {
  collaborator: ICollaborator;
  date: Dayjs;
  shiftStart: Dayjs | undefined;
  shiftEnd: Dayjs | undefined;
  attendanceStart: Dayjs | undefined;
  attendanceEnd: Dayjs | undefined;
  timeOffRequest: TimeOffType | undefined;
  isRemote: boolean;
}

export enum WorkingDayType {
  NonComputableShift = "Jornada no computable",
  Vacation = "Vacaciones",
  PersonalLeave = "Asuntos propios",
  RestDay = "Descanso",
  SimulatedAsistance = "Asistencia simulada",
  SickLeave = "Incapacidad",
  ForceMajeure = "Fuerza mayor",
  ShiftToBeCompensated = "Jornada a reponer",
  JustifiedAbsenceByCompany = "Inasistencia justificada por la empresa",
  AuthorizedUnjustifiedAbsence = "Falta injustificada autorizada",
  UnjustifiedAbsence = "Falta injustificada",
  OrdinaryShift = "Jornada ordinaria",
  LatePermission = "Permiso de llegar tarde",
  EarlyLeavePermission = "Permiso de salida anticipada",
  UnscheduledWork = "Trabajo no calendarizado",
  Tardiness = "Retardo",
  EarlyDeparture = "Salida anticipada",
  IncompleteRecord = "Registro incompleto",
  CompensationShift = "Reposición de jornada",
}

export interface CollaboratorDayReport {
  collaborator: ICollaborator;
  date: Dayjs;
  shiftStart?: Dayjs;
  shiftEnd?: Dayjs;
  attendanceStart?: Dayjs;
  attendanceEnd?: Dayjs;
  timeOffRequest?: TimeOffType;
  workingDayType: WorkingDayType;
  assignedHours: number;
  workedHours: number;
  extraHours: number;
  delayMinutes: number;
  anticipatedMinutes: number;
}

export interface CollaboratorDaysReportsWrapper {
  collaborator: ICollaborator;
  collaboratorDayReports: CollaboratorDayReport[];
}

// todo
export interface CollaboratorAttendanceReport {
  collaborator: ICollaborator;
  halfMonthStartDate: Dayjs;
  halfMonthEndDate: Dayjs;
  collaboratorDayReports: CollaboratorDayReport[];
  periodHours: PeriodHours;
  concludedWeeksHours: ConcludedWeekHours;
  tardiness: number;
  punctualityBonus: boolean;
}

export interface PeriodHours {
  workedHours: number;
  estimatedWorkedHours: number;
  simulatedAsistanceHours: number;
  toBeCompensatedHours: number;
  vacationHours: number;
  personalLeaveHours: number;
  justifiedAbsenceByCompanyHours: number;
  nonComputableHours: number;
  compensationHours: number;
  sickLeaveHours: number;
  authorizedUnjustifiedAbsenceHours: number;
  unjustifiedAbsenceHours: number;
  publicHolidaysHours: number;
  workedSundayHours: number;
}

export interface ConcludedWeekHours {
  restWorkedHours: number;
  singlePlayWorkedExtraHours: number;
  doublePlayWorkedExtraHours: number;
  triplePlayWorkedExtraHours: number;
  notWorkedHours: number;
}
