import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  isDevMode,
  transformDayjsToMexicoStartOfDay,
} from "../../../../shared/helpers";
import dayjs from "dayjs";
import { AttendanceReportsService } from "../../../../services";
import { AttendanceRecordReportViewSelector } from "../components/AttendanceRecordReportViewSelector";
import { useAuthHook, useJobsHook } from "../../../../hooks";
import { CollaboratorAttendanceReportResponse } from "../../../../shared/types";
import { AttendanceReport } from "../components/AttendanceReport";
import { fireSwalError } from "../../../../helpers";
import { useNavigate } from "react-router-dom";

export const AttendanceRecordReportsIndex = () => {
  const navigate = useNavigate();
  const attendanceReportsService = new AttendanceReportsService();

  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [attendanceReports, setAttendanceReports] = useState<
    CollaboratorAttendanceReportResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const { sortedCollaboratorIdsByJob } = useJobsHook();
  const { isAdminOrManager } = useAuthHook();
  // Ref to track if data is being fetched
  const isFetching = useRef(false);

  if (!isAdminOrManager) {
    console.log("No tienes permisos para acceder a esta página");
    fireSwalError("No tienes permisos para acceder a esta página").then(() => {
      navigate("/dashboard/attendance-records");
    });
  }

  useEffect(() => {
    if (!isAdminOrManager) return;
    if (!startDate || !endDate || isFetching.current) return;

    const mxStartDate = transformDayjsToMexicoStartOfDay(startDate);
    const mxEndDate = transformDayjsToMexicoStartOfDay(endDate);

    setIsLoading(true);

    attendanceReportsService
      .getAll({
        periodStartDate: mxStartDate.toISOString(),
        periodEndDate: mxEndDate.toISOString(),
      })
      .then((response) => {
        const sortedReports = response.data.sort((a, b) => {
          const indexA = sortedCollaboratorIdsByJob.indexOf(a.collaboratorId);
          const indexB = sortedCollaboratorIdsByJob.indexOf(b.collaboratorId);
          // Handle cases where IDs aren't in sortedCollaboratorIdsByJob
          if (indexA === -1) return 1; // Move items not in list to end
          if (indexB === -1) return -1;
          return indexA - indexB;
        });
        setAttendanceReports(sortedReports);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [startDate, endDate]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <AttendanceRecordReportViewSelector
        handleSetStartDate={setStartDate}
        handleSetEndDate={setEndDate}
        isLoading={isLoading}
      />
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          {attendanceReports.map((report) => (
            <AttendanceReport key={report.collaboratorId} report={report} />
          ))}
        </>
      )}
      {isDevMode && (
        <pre>
          {JSON.stringify({ attendanceRecords: attendanceReports }, null, 2)}
        </pre>
      )}
    </Box>
  );
};
