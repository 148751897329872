import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  EmploymentFormValues,
  ICollaborator,
} from "../../../../../shared/types";
import { useAuthHook, useCollaboratorHook } from "../../../../../hooks";
import { EmploymentService } from "../../../../../services";
import { EmploymentForm } from "./EmploymentForm";
import { EMPTY_EMPLOYMENT_FORM_VALUES } from "../../../../../shared/constants/employment.constants";

type Props = {
  formValues: EmploymentFormValues;
};

export const EmploymentFormContainer = ({
  formValues: initialFormValues,
}: Props) => {
  const { getCollaboratorById } = useCollaboratorHook();
  const [previousEmployment, setPreviousEmployment] =
    useState<EmploymentFormValues>(EMPTY_EMPLOYMENT_FORM_VALUES);

  const [selectedCollaborator, setSelectedCollaborator] = useState<
    ICollaborator | undefined
  >(getCollaboratorById(initialFormValues.collaboratorId));

  const service = new EmploymentService();
  const { isAdmin } = useAuthHook();

  const handleSelectCollaborator = (collaboratorId: string) => {
    setSelectedCollaborator(getCollaboratorById(collaboratorId));
  };

  useEffect(() => {
    if (selectedCollaborator) {
      service
        .getPreviousEmployment(selectedCollaborator.id)
        .then((employment) => {
          setPreviousEmployment(employment || EMPTY_EMPLOYMENT_FORM_VALUES);
        });
    } else {
      setPreviousEmployment(EMPTY_EMPLOYMENT_FORM_VALUES);
    }
  }, [selectedCollaborator]);

  console.log("previousEmployment", previousEmployment);

  return (
    <Box>
      <Grid container columnSpacing={8}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="h5">
              {previousEmployment.collaboratorId
                ? "Último Empleo"
                : "No hay empleo anterior"}
            </Typography>
            <EmploymentForm
              formValues={previousEmployment}
              handleSelectCollaborator={handleSelectCollaborator}
              displayOnly={true}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography variant="h5">Nuevo Empleo</Typography>
            <EmploymentForm
              formValues={initialFormValues}
              handleSelectCollaborator={handleSelectCollaborator}
              displayOnly={false}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
