import * as Yup from "yup";
import { PaymentType } from "../../../../../shared/enums";
import { MAX_WORK_WEEK_LIMIT } from "../../../../../shared";

export const employmentValidationSchema = Yup.object().shape({
  collaboratorId: Yup.string()
    .required("Colaborador es requerido")
    .min(10, "Colaborador es requerido"),
  jobId: Yup.string().required("Posición es requerida"),
  employmentStartDate: Yup.date().required("Fecha de inicio es requerida"),
  employmentEndDate: Yup.date().nullable(),
  weeklyHours: Yup.number()
    .required("Horas semanales son requeridas")
    .min(1, "Debe ser mayor a 0")
    .max(MAX_WORK_WEEK_LIMIT, `No puede exceder ${MAX_WORK_WEEK_LIMIT} horas`),
  paymentType: Yup.string()
    .oneOf(Object.values(PaymentType))
    .required("Tipo de pago es requerido"),
  isActive: Yup.boolean(),
  extraCompensations: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("El nombre es requerido"),
      description: Yup.string(),
      attendanceRelated: Yup.boolean().required("Este campo es requerido"),
      amount: Yup.number()
        .min(0, "El monto debe ser positivo")
        .required("El monto es requerido"),
      incomeType: Yup.string().required("El tipo de ingreso es requerido"),
    })
  ),
});
