import React from "react";
import { EmploymentFormValues } from "../../../../../shared/types";
import { useJobsHook } from "../../../../../shared/hooks";
import dayjs from "dayjs";
import { MAX_WORK_WEEK_LIMIT } from "../../../../../shared";
import { PaymentType } from "../../../../../shared/enums";
import { EmploymentFormContainer } from "../components";

export const EmploymentNew = () => {
  const { getJobByTitle } = useJobsHook();
  const formValues: EmploymentFormValues = {
    collaboratorId: "",
    jobId: getJobByTitle("asistente veterinario b")?.id ?? "",
    employmentStartDate: dayjs(),
    employmentEndDate: undefined,
    isActive: true,
    weeklyHours: MAX_WORK_WEEK_LIMIT, // required
    paymentType: PaymentType.SALARY, // required
    seniorityBonusPercentage: 0, // calculate collaborator and constant
    comissionBonusPercentage: 0, // calculate collaborator and constant
    // todo: change the name to adjustedFixedIncome
    fixedIncomeByPosition: 0, // calculate job and employment
    additionalFixedIncome: 0,
    fixedIncome: 0, // calculate employment
    minimumOrdinaryIncome: 0, // calculate employment
    degreeBonus: 0, // calculate collaborator and constant
    receptionBonus: 0,
    trainingSupport: 0,
    physicalActivitySupport: 0,
    contributionBaseSalary: 0, // calculate payrolls
    averageOrdinaryIncome: 0, // calculate payrolls
    averageIntegratedIncome: 0, // calculate payrolls
    averageComissionIncome: 0, // calculate payrolls
    extraCompensations: [],
  };
  return <EmploymentFormContainer formValues={formValues} />;
};
