import React from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
  useAuthHook,
  useCollaboratorHook,
} from "../../../../../hooks";
import dayjs from "dayjs";
import { PaymentType } from "../../../../../shared/enums";
import { formatCurrency } from "../../../../../utils/formatters";
import { Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CustomAvatar } from "../../../../../components/avatar/CustomAvatar";
import { EmploymentThunks } from "../../../../../store/thunks";
import { useJobsHook } from "../../../../../shared/hooks";
import { useNavigate } from "react-router-dom";
import { fireSwalConfirmation } from "../../../../../helpers/swal.helpers";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const EmploymentList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { employments, isLoading } = useAppSelector(
    (state) => state.employments
  );

  const { isAdminOrManager } = useAuthHook();

  const { getJobTitleById } = useJobsHook();

  const { getCollaboratorCodeById, getCollaboratorById } =
    useCollaboratorHook();

  const handleDeleteEmployment = (employmentId: string) => {
    fireSwalConfirmation(
      "¿Estás seguro de que deseas eliminar este empleo?"
    ).then((result: boolean) => {
      if (result) {
        dispatch(EmploymentThunks.deleteItem(employmentId));
      }
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Employee</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Workload & Income</TableCell>
            <TableCell>Employment Period</TableCell>
            <TableCell>Financial Information</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employments?.map((employment) => (
            <TableRow key={employment.id} sx={{ "& td": { py: 2 } }}>
              <TableCell>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomAvatar
                    collaborator={
                      getCollaboratorById(employment.collaboratorId)!
                    }
                  />{" "}
                  {getCollaboratorCodeById(employment.collaboratorId)}
                </Box>
              </TableCell>
              <TableCell>{getJobTitleById(employment.jobId)}</TableCell>
              <TableCell>
                <Chip
                  label={employment.paymentType}
                  color={
                    employment.paymentType === PaymentType.SALARY
                      ? "primary"
                      : "secondary"
                  }
                  size="small"
                />
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    {employment.weeklyHours}h/week
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Base: {formatCurrency(employment.fixedIncome)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Min: {formatCurrency(employment.minimumOrdinaryIncome)}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ mb: 0.5 }}
                  >
                    <Typography variant="subtitle2">
                      {dayjs(employment.employmentStartDate).format(
                        "MMM DD, YYYY"
                      )}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      →
                    </Typography>
                    <Typography variant="subtitle2">
                      {employment.employmentEndDate
                        ? dayjs(employment.employmentEndDate).format(
                            "MMM DD, YYYY"
                          )
                        : "Present"}
                    </Typography>
                  </Stack>
                  <Chip
                    size="small"
                    label={employment.isActive ? "Active" : "Inactive"}
                    color={employment.isActive ? "success" : "default"}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    SBC: {formatCurrency(employment.contributionBaseSalary)}
                  </Typography>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="body2" color="text.secondary">
                      AvgOrd: {formatCurrency(employment.averageOrdinaryIncome)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      AvgInt:{" "}
                      {formatCurrency(employment.averageIntegratedIncome)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      AvgCom:{" "}
                      {formatCurrency(employment.averageComissionIncome)}
                    </Typography>
                  </Stack>
                </Box>
              </TableCell>
              <TableCell>
                <Stack direction="row" spacing={1} justifyContent="center">
                  <IconButton
                    size="small"
                    onClick={() =>
                      navigate(
                        `/dashboard/collaborators/employments/${employment.id}`
                      )
                    }
                    color="info"
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                  {isAdminOrManager && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        navigate(
                          `/dashboard/collaborators/employments/edit/${employment.id}`
                        )
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                  {isAdminOrManager && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        navigate(
                          `/dashboard/collaborators/employments/copy/${employment.id}`
                        )
                      }
                      color="info"
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  )}
                  {isAdminOrManager && (
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteEmployment(employment.id)}
                      color="error"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
