import React from "react";
import { Field, FieldProps } from "formik";
import { TextField, TextFieldProps } from "@mui/material";

interface Props extends Omit<TextFieldProps, "name"> {
  name: string;
}

const SimpleFormikTextFieldComponent: React.FC<Props> = ({
  name,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <TextField
          {...field}
          {...props}
          value={field.value ?? ""} // Ensure empty string when no value
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    </Field>
  );
};

export const SimpleFormikTextField = React.memo(SimpleFormikTextFieldComponent);
