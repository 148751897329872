import { createSlice } from "@reduxjs/toolkit";
import { EmploymentResponse } from "../../shared/types";
import { EmploymentThunks, TimeOffRequestThunks } from "../thunks";

interface State {
  employments: EmploymentResponse[];
  isLoading: boolean;
}

const initialState: State = {
  employments: [],
  isLoading: true,
};

export const employmentSlice = createSlice({
  name: "employment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(EmploymentThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(EmploymentThunks.fetchItems.fulfilled, (state, action) => {
      state.employments = action.payload;
      state.isLoading = false;
    });
    builder.addCase(EmploymentThunks.fetchItems.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(EmploymentThunks.createItem.fulfilled, (state, action) => {
      state.employments.push(action.payload);
    });
    builder.addCase(EmploymentThunks.updateItem.fulfilled, (state, action) => {
      const index = state.employments.findIndex(
        (employment) => employment.id === action.payload.id
      );
      state.employments[index] = action.payload;
    });
    builder.addCase(EmploymentThunks.deleteItem.fulfilled, (state, action) => {
      state.employments = state.employments.filter(
        (employment) => employment.id !== action.payload
      );
    });
  },
});

export default employmentSlice.reducer;
